import React, { useState, useContext } from 'react';

import {
  NavLink as RouterLink,
  BrowserRouter as Router,
  Switch, Route
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Button, Hidden, Link, Typography
} from '@material-ui/core';

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import {AppContext} from '../../contexts/AppContext';

import { DBAddress } from '../../model/interface/DBModels';
import UserDAO from "../../model/dao/UserDAO";

import AddressForm from "../../components/checkout/address-page/AddressForm";
import PaymentForm from "../../components/checkout/payment-page/PaymentForm";
import ShippingForm from "../../components/checkout/shipping-page/ShippingForm";
import ReviewForm from "../../components/checkout/review-page/ReviewForm";
import ConfirmationForm from "../../components/checkout/confirmation-page/ConfirmationForm";
import OrderSummary from "../../components/OrderSummary";

export default function CheckoutPage() {

  // -------------- states -------------- //
  const context = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setError] = useState(false);
  const [accountEmail, setAccountEmail] = useState<string>();
  const [isGuestAccount, setIsGuestAccount] = useState<boolean>();

  const [orderShippingAddress, setOrderShippingAddress] = useState<DBAddress>();
  const [orderSavedShippingIndex, setOrderSavedShippingIndex] = useState<number>(-1)
  const [orderPaymentMethod, setOrderPaymentMethod] = useState<string>();
  const [orderSavedPaymentIndex, setOrderSavedPaymentIndex] = useState<number>(-1)
  const [orderId, setOrderId] = useState<string>();
  const [orderConfirmed, setOrderConfirmed] = useState(false);

  // console.log("------ ORDER STATES ------")
  // console.log(orderShippingAddress);
  // console.log(orderPaymentMethod);
  // console.log("--------------------------")

  // -------------- hook to get account info -------------- //

  React.useEffect(() => {
    let isActive = true;
    setLoading(true);
    setError(false);

    UserDAO.getAccountInfo(context.token)
      .then((accountInfo) => {
            console.log(accountInfo)
            setAccountEmail(accountInfo.email);
            setIsGuestAccount(accountInfo.guest);
            setLoading(false);
            setError(false);
          }
      ).catch(() => {
        if (isActive) {
            setError(true);
            setLoading(false);
        }
    });

    return () => {
      isActive = false;
    };
  }, [context.token]);

  // -------------- listener for refreshes -------------- //

  const handleOnBeforeUnload = (e: BeforeUnloadEvent) => {
    const message = 'refresh hit';
    e.returnValue = message;
    return message;
  }

  React.useEffect(() => {
    if(orderConfirmed === false) {
      console.log("listener added")
      window.onbeforeunload = handleOnBeforeUnload;
    }
  }, [orderConfirmed])


  // -------------- the actual page -------------- //

  return (
    <div className="page-offset checkout-page">
      {
        (orderConfirmed === false)
        ?
          <div className="back-to-cart-button"> {/* main page */}
            <Link component={RouterLink} to="/cart" variant="body2" onClick={() => window.onbeforeunload = null}>
              &lt; Return to cart
            </Link>
          </div>
        : ""
      }
      <Grid container item justify="center" xs={12}> {/* main page */}
        <Grid item xs={12}> {/* title */}
          <Typography variant="h5" noWrap>
             Checkout
          </Typography>
        </Grid>
        <Grid container spacing={4}>
          <Hidden mdUp> 
            <Grid item xs={12} md={3}> {/* summary + purchase button */}
              <br/>
              {
                (orderConfirmed === true)
                ?
                <span>
                  <Button
                    variant="contained" color="primary" component={RouterLink} to="/"
                    onClick={() => window.onbeforeunload = null}
                    fullWidth={true} disableElevation
                  >
                    Continue Shopping
                  </Button>
                  <br/><br/>
                </span>
                : ""
              }
              <OrderSummary
                orderConfirmed={orderConfirmed}/>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9}> {/* outer wrapper for form */}
            <Router>
              <Switch>
                <Route exact path={`/checkout`}>
                  <AddressPage
                    isGuestAccount={isGuestAccount}
                    accountEmail={accountEmail}
                    setAccountEmail={setAccountEmail}
                    orderShippingAddress={orderShippingAddress}
                    setOrderShippingAddress={setOrderShippingAddress}
                    orderSavedShippingIndex={orderSavedShippingIndex}
                    setOrderSavedShippingIndex={setOrderSavedShippingIndex}/>
                </Route>
                <Route path={`/checkout/payment`}>
                  <PaymentPage
                    isGuestAccount={isGuestAccount}
                    orderShippingAddress={orderShippingAddress}
                    orderPaymentMethod={orderPaymentMethod}
                    setOrderPaymentMethod={setOrderPaymentMethod}
                    orderSavedPaymentIndex={orderSavedPaymentIndex}
                    setOrderSavedPaymentIndex={setOrderSavedPaymentIndex}/>
                </Route>
                <Route path={`/checkout/shipping`}>
                  <ShippingPage/>
                </Route>
                <Route path={`/checkout/review`}>
                  <ReviewPage
                    isGuestAccount={isGuestAccount}
                    accountEmail={accountEmail}
                    orderShippingAddress={orderShippingAddress}
                    orderPaymentMethod={orderPaymentMethod}
                    setOrderId={setOrderId}
                    setOrderConfirmed={setOrderConfirmed}/>
                </Route>
                <Route path={`/checkout/complete`}>
                  <ConfirmationPage
                    orderId={orderId}/>
                </Route>
              </Switch>
            </Router>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={3}>
              <OrderSummary
                orderConfirmed={orderConfirmed}/>
              {
                (orderConfirmed === true)
                ?
                <Button
                variant="contained" color="inherit" component={RouterLink} to="/"
                onClick={() => window.onbeforeunload = null}
                className="continue-shopping-button" fullWidth={true} disableElevation
                >
                  Continue Shopping
                </Button>
                : ""
              }
            </Grid>
          </Hidden>
          
        </Grid>
      </Grid>
      <br/>
    </div>
  );
}

// -------------- individual pages for neatness -------------- //

function AddressPage(props: any) {
  return (
    <div> 
      <AddressForm
        isGuestAccount={props.isGuestAccount}
        accountEmail={props.accountEmail}
        setAccountEmail={props.setAccountEmail}
        orderShippingAddress={props.orderShippingAddress}
        setOrderShippingAddress={props.setOrderShippingAddress}
        orderSavedShippingIndex={props.orderSavedShippingIndex}
        setOrderSavedShippingIndex={props.setOrderSavedShippingIndex}/>
    </div>
  );
}

function PaymentPage(props: any) {
  const stripePromise = loadStripe('pk_test_51HaWKaJuo8U7byNnzJnvPTZN2WRmtTDhB5IvxagbluaRQ2VVqkTrzYdCb78ek9zGQDgPt1cVIhFJ83VWPO55MSRL00rcyRvVo0');

  return (
      <div>
        <Elements stripe={stripePromise}>
          <PaymentForm
            isGuestAccount={props.isGuestAccount}
            orderShippingAddress={props.orderShippingAddress}
            orderPaymentMethod={props.orderPaymentMethod}
            setOrderPaymentMethod={props.setOrderPaymentMethod}
            orderSavedPaymentIndex={props.orderSavedPaymentIndex}
            setOrderSavedPaymentIndex={props.setOrderSavedPaymentIndex}/>
        </Elements>
      </div>
  );
}

function ShippingPage(props: any) {
  return (
    <div>
      <ShippingForm />
    </div>
  );
}

function ReviewPage(props: any) {
  return (
    <div>
      <ReviewForm
        isGuestAccount={props.isGuestAccount}
        accountEmail={props.accountEmail}
        orderShippingAddress={props.orderShippingAddress}
        orderPaymentMethod={props.orderPaymentMethod}
        setOrderId={props.setOrderId}
        setOrderConfirmed={props.setOrderConfirmed}/>
    </div>
  );
}

function ConfirmationPage(props:any) {
  return (
    <div>
      <ConfirmationForm
       orderId={props.orderId}/>
    </div>
  );
}
