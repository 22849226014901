import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import QueryString from "query-string";

import Grid from '@material-ui/core/Grid';
import { Typography, Divider, Hidden, CircularProgress, Paper } from '@material-ui/core';

import {AppContext} from '../../contexts/AppContext';
import {getQueryParamString} from "../../components/_helpers/UrlHelper";
import SearchResults from './SearchResults';
import SearchListener from './SearchListener';
import FilterDisplay from './FilterDisplay';
import { DBAbstractItem, DBFilter } from '../../model/interface/DBModels';
import AbstractItemDisplay from './components/AbstractItemDisplay';
import { Alert, Pagination } from '@material-ui/lab';
import DropDown from './components/DropDown';

type selectedVals = { [filterName: string]: string[] };

type State = {
   fullResults: DBAbstractItem[],
   filteredResults: DBAbstractItem[],
   filters: DBFilter[],
   maxPages: number,
   currentPage: number,
   perPage: number,
   isLoading: boolean,
   resultCount: number,
   selected: selectedVals,
   alternateTitleText: string,
   resultTypeText: string,
};

type Props = RouteComponentProps & {
   fullResults?: DBAbstractItem[],
   filters?: DBFilter[],
   maxPages?: number,
   currentPage?: number,
   perPage?: number,
   resultCount?:number,
   alternateTitleText?: string,
   resultTypeText?: string,
};

class SearchPage extends Component<Props, State> implements SearchListener{
   state: State = {
      fullResults: [],
      filteredResults: [],
      filters: [],
      maxPages: 1,
      currentPage: 1,
      perPage: 20,
      isLoading: true,
      resultCount: 0,
      selected: {},
      alternateTitleText: "",
      resultTypeText: "Results",
   }

   static contextType = AppContext;

   constructor(props: Props) {
      super(props);

      if (props.filters && props.fullResults) {
         this.state = {
            filters: props.filters,
            filteredResults: [],
            fullResults: props.fullResults,
            maxPages: props.maxPages || 1,
            currentPage: props.currentPage || 1,
            perPage: props.perPage || 20,
            isLoading: true,
            resultCount: props.resultCount || props.fullResults.length,
            selected: {},
            alternateTitleText: props.alternateTitleText || "",
            resultTypeText: props.resultTypeText || "Results",
         };
      }
      if (props.alternateTitleText) {
         this.state = {
            filters: [],
            filteredResults: [],
            fullResults: [],
            maxPages: props.maxPages || 1,
            currentPage: props.currentPage || 1,
            perPage: props.perPage || 20,
            isLoading: true,
            resultCount: props.resultCount || 0,
            selected: {},
            alternateTitleText: props.alternateTitleText,
            resultTypeText: props.resultTypeText || "Results",
         }
      }
   }

   setFilteredResults = (results: DBAbstractItem[]) => {
      this.setState({filteredResults: results});
   }

   async searchUpdated(updatedText: SearchResults) {
      console.log("updating search");
      console.log(getQueryParamString(updatedText.queryParams));
      this.setState({
         isLoading: true,
      })

      const response = await updatedText.getResults();

      this.setState({
         maxPages: response.maxPages,
         //perPage: response.perPage,
         filteredResults: response.results,
         filters: response.filters || [],
         fullResults: response.results,
         isLoading: false,
         resultCount: response.resultCount || 0,
      });
   }

   componentDidMount() {
      let searchFlag = true;
      if (this.props.filters && this.props.fullResults) {
         searchFlag = false;
         console.log("setting filters " + this.props.filters.length);
         this.setState({
            filters: this.props.filters,
            filteredResults: this.props.fullResults,
            fullResults: this.props.fullResults,
            maxPages: this.props.maxPages || 1,
            isLoading: false,
         });
      }
      this.context.searchListener.initialize(this, searchFlag);
      if (this.props.location.search) {
         const params = QueryString.parse(this.props.location.search);
         let temp: selectedVals = {};
         for (const param in params) {
            if (params[param]) {
               if (typeof params[param] === "string") {
                  temp[param] = new Array(params[param] as string);
               } else {
                  temp[param] = params[param] as string[];
               }
            }
         }
         this.setState({selected: temp});
         this.context.searchListener.replaceQueryParams(params);
         this.context.searchListener.updateText(params.searchText);
         this.context.setSearchText(params.searchText);
         if (params.page && typeof params.page === "string") {
            this.setState({currentPage: parseInt(params.page)});
         }
      }
   }

   componentWillUnmount() {
      this.context.searchListener.destruct(this);
   }

   handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
      console.log("getting page: " + page);
      this.setState({currentPage: page});
      this.context.searchListener.updateQueryParam("page", page);
      this.context.searchListener.updateText(this.context.searchText);
      this.props.history.push({
         pathname: this.props.location.pathname,
         search: getQueryParamString(this.context.searchListener.getQueryParams()),
      });
    };

   handleMinPrice = (val: string) => {
      if (val) {
         let temp:selectedVals = {};
         temp = Object.assign(temp, this.state.selected);
         temp.minPrice = new Array(val);
         this.setState({selected: temp});
      } else {
         let temp:selectedVals = {};
         temp = Object.assign(temp, this.state.selected);
         delete temp.minPrice;
         this.setState({selected: temp});
      }
   }

   handleMaxPrice = (val: string) => {
      if (val) {
         let temp:selectedVals = {};
         temp = Object.assign(temp, this.state.selected);
         temp.maxPrice = new Array(val);
         this.setState({selected: temp});
      } else {
         let temp:selectedVals = {};
         temp = Object.assign(temp, this.state.selected);
         delete temp.maxPrice;
         this.setState({selected: temp});
      }
   }

   handleSelection = (filtername: string) => {
      return (vals:string[]) => {
         if (vals.length > 0) {
            let temp:selectedVals = {};
            temp = Object.assign(temp, this.state.selected);
            temp[filtername] = vals;
            this.setState({selected: temp});
         } else {
            let temp:selectedVals = {};
            temp = Object.assign(temp, this.state.selected);
            delete temp[filtername];
            this.setState({selected: temp});
         }
      };
   }

   handleFiltering = () => {
      this.context.searchListener.clearQueryParams();
      this.context.searchListener.updateQueryParams(this.state.selected);
      this.context.searchListener.updateText(this.context.searchText);
      this.props.history.push({
         pathname: "/search",
         search: getQueryParamString(this.context.searchListener.getQueryParams()),
      });
   }

   handleClear = () => {
      this.setState({selected: {}});
      this.context.searchListener.clearQueryParams();
      this.context.searchListener.updateText(this.context.searchText);
      this.props.history.push({
         pathname: "/search",
         search: getQueryParamString(this.context.searchListener.getQueryParams()),
      });
   }

   buildResultsString = () => {
      const startNum = ((this.state.currentPage - 1) * this.state.perPage) + 1;
      
      return (
         "Showing " + 
         startNum + "-" + (this.state.currentPage * this.state.filteredResults.length) +
         " of " +
         this.state.resultCount + " " + this.state.resultTypeText
      );
   }

   PerPageSelector = () => {
      return (
      <div className="u-paddingHorizontal-1 u-flexItem">
         {/* <Typography>
            Per Page: 
         </Typography>
         <DropDown inputArray={["20", "50", "100"]} selected={(val: string) => this.setState({perPage: parseInt(val)})}/> */}
      </div>
      );
   }

   ResultsDisplay = () => {
      if (this.state.isLoading) {
         return (
            <Paper className="u-flexItem" elevation={3}>
               <div className="u-padding-4 u-centerVerticalRelative">
                  <CircularProgress size={"4em"}/>
               </div>
            </Paper>
         );
      } else if (this.state.filteredResults) {
         return (
         <div>
            {this.state.filteredResults.map(
               (result) => (
                  <AbstractItemDisplay key={result.id} item={result} />
                  )
            )}
         </div>);
      } else {
         return <Alert severity="info">Sorry, we {"couldn't"} find any matching results.</Alert>;
      }
    };

   render() {
      return (
         <div className="search-page page-offset">
            <Grid container spacing={3}>
               <Grid item xs={12}>
                  <div className="page-title">
                     <Typography variant="h4" noWrap>
                        {this.state.alternateTitleText || 'Search Results for "' + this.context.searchText + '"'}
                     </Typography>
                  </div>
                  {this.state.isLoading || !this.state.filteredResults.length ?
                     <div className="u-padding-2"></div>
                  :
                     <div className="u-flexItem">
                        <Typography variant="h6" noWrap>
                           {this.state.filteredResults && this.buildResultsString()}
                        </Typography>
                        <this.PerPageSelector/>
                     </div>
                  }
               </Grid>
               <Grid item md={3} xs={12}>
                  <FilterDisplay
                     handleMinPrice={this.handleMinPrice}
                     handleMaxPrice={this.handleMaxPrice}
                     handleSelection={this.handleSelection}
                     handleFiltering={this.handleFiltering}
                     handleClear={this.handleClear}
                     filters={this.state.filters}
                     selected={this.state.selected} />
               </Grid>
               <Grid item lg={7} md={9} xs={12}>
                  <Grid item xs={12}>
                     <this.ResultsDisplay/>
                  </Grid>
               </Grid>
               <Hidden mdDown>
                  <Grid item xs={1}>
                     <Divider orientation="vertical" />
                  </Grid>
               </Hidden>
               <Hidden mdDown>
                  <Grid item xs={1}>
                  </Grid>
               </Hidden>
               <Grid item xs={12} zeroMinWidth>
                  <div className="pagination">
                     <Pagination
                        shape="rounded"
                        count={this.state.maxPages}
                        page={this.state.currentPage}
                        onChange={this.handlePageChange}
                     />
                  </div>
               </Grid>
            </Grid>
         </div>
      );
   }
}

export default withRouter(SearchPage);