import React, { useState, useContext } from 'react';

import {
  useHistory,
  NavLink as RouterLink,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Paper, Button, CircularProgress, Dialog
} from '@material-ui/core';
import { DBAddress, DBCard } from '../../../model/interface/DBModels';
import {AppContext} from '../../../contexts/AppContext';
import CheckoutStepper from "../CheckoutStepper";
import StripeDAO from "../../../model/dao/StripeDAO";
import SavedPayments from "./SavedPayments"
import PaymentInput from './PaymentInput';

import {
  useStripe, useElements, CardNumberElement
} from '@stripe/react-stripe-js';
import { labelToCode } from '../../_helpers/CountryType';

type Props = {
  isGuestAccount: boolean,
  orderShippingAddress: DBAddress,
  orderPaymentMethod: string, 
  setOrderPaymentMethod: any,
  orderSavedPaymentIndex: number,
  setOrderSavedPaymentIndex: any
};


export default function PaymentForm(props: Props) {

  // -------------- states/variables -------------- //

   const context = useContext(AppContext);
   let history = useHistory();

   const stripe = useStripe();
   const elements = useElements();

   const [loading, setLoading] = useState(true);
   const [checkingCard, setCheckingCard] = useState(false);
   const [errorMessage, setError] = useState(false);
   const [stripeErrorMessages, setStripeErrorMessages] = useState<string[]>([]);
   const [paymentList, setPaymentList] = useState<DBCard[]>([]);

   const [selectedIndex, setSelectedIndex] = useState(props.orderSavedPaymentIndex);
   const [cardFormVisible, setCardFormVisible] = useState((props.orderSavedPaymentIndex === -1));
   const [sameBillingAddress, setSameBillingAddress] = useState(true);

   // -------------- get all payments -------------- //

   React.useEffect(() => {
    let isActive = true;

    setLoading(true);
    setError(false);

    StripeDAO.getAllCards(context.token)
      .then((paymentList) => {
          if (paymentList) {
            let cards = (paymentList as any).cards;
            if(cards.length !== 0) {
              setPaymentList(cards);
              if(props.orderSavedPaymentIndex === -1 && props.orderPaymentMethod !== "") {
                let foundIndex = cards.findIndex((c: DBCard) => c.id === props.orderPaymentMethod)
                if(foundIndex !== -1) {
                  setSelectedIndex(foundIndex);
                  setCardFormVisible(false);
                }
              }
            }            
            setLoading(false);
            setError(false);
          }
      }).catch(() => {
          if (isActive) {
            setError(true);
            setLoading(false);
          }
      });

    return () => {
      isActive = false;
    };
  }, [context.token, props.orderPaymentMethod, props.orderSavedPaymentIndex]);

  // -------------- various helpers -------------- //


  const toggleFormVisibility = (changeTo: boolean) => {
    if(changeTo !== cardFormVisible) {
      if(changeTo === true) { // input new address
       setSelectedIndex(-1);
      }
      setCardFormVisible(changeTo);
    }
  }

  const makeBillingDetailsFromForm = (formData: FormData) => {

    let countryCode = labelToCode(String(formData.get("country")));
    console.log(countryCode)
    let newAddress = {
      "line1": String(formData.get("address-line-1")),
      "line2": String(formData.get("address-line-2")),
      "city": String(formData.get("city")),
      "state": String(formData.get("region")),
      "postal_code": String(formData.get("zip-code")),
      "country": countryCode
    }
    let newName = String(formData.get("name"));
    let newPhone = String(formData.get("phone"));
    let newDetails = {
      "address": newAddress,
      "name": newName,
      "phone": newPhone
    }
    return newDetails;
  }

  const makeBillingDetailsFromState = () => {
    let newAddress = {
      "line1": String(props.orderShippingAddress.line1),
      "line2": String(props.orderShippingAddress.line2),
      "city": String(props.orderShippingAddress.city),
      "state": String(props.orderShippingAddress.state),
      "postal_code": String(props.orderShippingAddress.postalCode),
      "country": String(props.orderShippingAddress.country),
    }
    let newName = String(props.orderShippingAddress.name);
    let newPhone = String(props.orderShippingAddress.phone);
    let newDetails = {
      "address": newAddress,
      "name": newName,
      "phone": newPhone
    }
    return newDetails;
  }

  const generateFriendlyError = (errorType: string, errorMessage: string) => {
    switch(errorType) {
      case "validation_error":
        return ["Your card could not be processed.", errorMessage];
      default:
        return ["Something went wrong.", "Please check your card details and try again."]
    }
  }

  const handleSubmitExisting = async(event: any) => {
    props.setOrderSavedPaymentIndex(selectedIndex);
    props.setOrderPaymentMethod(paymentList[selectedIndex].id);
    history.push("/checkout/shipping");
    setCheckingCard(false);
  }

  const handleSubmitNew = async(event: any) => {
    const clientInfo = await StripeDAO.addCard(context.token);

    /* between this comment and the next one
    is stuff to prevent typescript errors */
    if (stripe === null || elements === null) {
      throw new Error('!! stripe is null')
    }
    const cardElement = elements.getElement(CardNumberElement)
    if (cardElement === null) {
      throw new Error('!! element is null')
    }
    /* end typescript error preventors */


    let billingDetails;
    if(sameBillingAddress === false) { //grab address from form
      let form = document.querySelector("form");
      if(form !== null) {
        let formData = new FormData(form);
        billingDetails = makeBillingDetailsFromForm(formData);
      }
    }
    else { //pull info from overhead state (props.orderShippingAddress)
      billingDetails = makeBillingDetailsFromState();
    }

    // thank you https://stripe.com/docs/payments/save-and-reuse#web-create-setup-intent

    const result = await stripe.confirmCardSetup(clientInfo.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: billingDetails
      }
    });


    if (result.error) {
      if(result.error.message !== undefined) {
        setStripeErrorMessages(generateFriendlyError(result.error.type, result.error.message))
      }
    } else {
      props.setOrderSavedPaymentIndex(-1);
      props.setOrderPaymentMethod(result.setupIntent?.payment_method);
      history.push("/checkout/shipping")
    }
    setCheckingCard(false);
  }

  // -------------- submit -------------- //

   const handleSubmit = async (event: any) => {
    event.preventDefault();
    setCheckingCard(true);

    if(selectedIndex !== -1) { // select one that exists in the system
      handleSubmitExisting(event);
    }
    else { // else deal with stripe stuff
      handleSubmitNew(event);
    }
  };

  // -------------- return a working page :) -------------- //

  let nextIsDisabled =
    (cardFormVisible === false)
    ?
        (selectedIndex !== -1)
        ? false
        : true
    :
        false

   return (
     <form id="payment-form" onSubmit={handleSubmit}> {/* outer wrapper */}
      <Grid item xs={12}> {/* title */}
        <CheckoutStepper step={1}/>
      </Grid>
      <Dialog
        open={checkingCard}
        container={() => document.getElementById('payment-form-paper')}>
        <div className="loading-screen">
          <Typography variant="subtitle1" noWrap align="center">
            <CircularProgress size={'1em'}/>
            <br/>
            Checking your card...
          </Typography>
          </div>
      </Dialog>
      <Paper elevation={3} id="payment-form-paper"> {/* info */}
        {
          loading
          ?
          <div className="loading-screen">
            <Typography variant="subtitle1" noWrap align="center">
              <CircularProgress size={'1em'}/>
              <br/>
              Loading payment methods...
            </Typography>
          </div>
          :
          <Grid item xs={12} className="checkout-form-inner">
            <SavedPayments
                paymentList={paymentList}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                cardFormVisible={cardFormVisible}
                toggleFormVisibility={toggleFormVisibility}
              />
            {
              (cardFormVisible === true)
              ?
                <div>
                  <PaymentInput
                    errorMessages={stripeErrorMessages}
                    sameBillingAddress={sameBillingAddress}
                    setSameBillingAddress={setSameBillingAddress}/>
                </div>
              :
                ""
            }
          </Grid>
        }
      </Paper>
      <Grid item container xs={12} justify="space-between">
        <Button
          variant="contained" color="primary" component={RouterLink} to={`/checkout/`}
          disabled={loading} className="checkout-button" disableElevation
        >
          Back
        </Button>
        <Button
          variant="contained" color="primary" type="submit"
          disabled={loading || !stripe || nextIsDisabled || checkingCard} className="checkout-button" disableElevation
        >
          Next
        </Button>
      </Grid>
     </form>
   );
}
