import React, {useState, useEffect} from "react";
import * as d3 from 'd3';

import { DatePrice, DBItem } from "../../../model/interface/DBModels";
import { AxisDomain } from "d3";
import { Grid, Typography } from "@material-ui/core";


type Props = {
   items: DBItem[]
}

export default function PriceHistory(props: Props) {
   const [sellerPrices, setSellerPrices] = useState<{ date: Date; price: number; }[][]>([]);
   const [sellerLogos, setSellerLogos] = useState<(string | undefined)[][]>([]);
   const [colors, setColors] = useState<string[]>([]);
   const [divWidth, setDivWidth] = useState(0);

   // https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
   function stringToColor(str: string | undefined) {
      let hash = 0;
      if(str === undefined) {
         return "#000000";
      }
      if(str.length === 0) {
         return "#000000";
      }
      for(let i = 0; i < str.length; i++) {
         hash = str.charCodeAt(i) + ((hash << 5) - hash);
         hash = hash & hash;
      }
      let hue = hash % 360;
      return "hsl(" + hue + ", 75%, 65%)";
   }

   function findEarliestDate(list: DBItem[]) {
      let tempOldest = new Date();
      tempOldest.setDate(tempOldest.getDate() - 14);
      if(list !== undefined) {
         for(let i = 0; i < list.length; i++) {
            let sellerHistory = list[i].priceHistory as DatePrice[];
               for(let j = 0; j < sellerHistory.length; j++) {
                  let sellerDate = new Date(sellerHistory[j].date);
                  if(sellerDate < tempOldest) {
                     tempOldest = sellerDate;
                  }
               }
         }
      }
      return tempOldest;
   }

   // format data
   useEffect(() => {
      let tempPrices = [];
      let tempLogos: (string | undefined)[][] = [];
      let tempColors = [];
      let earliestDate = findEarliestDate(props.items);
      // each props.items[i] is stuff from a seller
      if(props.items !== undefined) {
         for(let i = 0; i < props.items.length; i++) {
            // convert date strings to dates
            let sellerHistory = props.items[i].priceHistory as DatePrice[];
            let sellerFormattedHistory = [];
            if(sellerHistory.length === 0) {
               // let d = new Date();
               // d.setDate(d.getDate() - 14);
               sellerFormattedHistory.push({
                  date: earliestDate, 
                  price: props.items[i].price
               })
            }
            else {
               for(let j = 0; j < sellerHistory.length; j++) {
                  sellerFormattedHistory.push({
                     date: new Date(sellerHistory[j].date), 
                     price: sellerHistory[j].price
                  })
               }
            }
            // append today's price for the end line
            sellerFormattedHistory.push({
               date: new Date(), 
               price: props.items[i].price
            })
            tempPrices.push(sellerFormattedHistory)

            // handle logos
            tempLogos.push([props.items[i].sellerLogo, props.items[i].seller])

            // handle colors
            let color = stringToColor(props.items[i].seller)
            tempColors.push(color);
         }
      }
      
      setSellerPrices(tempPrices);
      setSellerLogos(tempLogos);
      setColors(tempColors);
   }, [])

   // set up listener
   useEffect(() => {
      const getNewWidth = () => {
         let tempDivWidth = document.getElementById('graph-holder')?.getBoundingClientRect().width;
         if(tempDivWidth !== undefined) {
            setDivWidth(tempDivWidth - 115)
         }
         console.log("tempDivWidth:", tempDivWidth)
      }
      
      window.addEventListener("resize", getNewWidth);
      getNewWidth();

      return () => window.removeEventListener("resize", getNewWidth);
   }, []);

   return (
      <div id="graph-holder">
         <div className="u-padding-2">
            <Typography variant="h6" color="secondary">
               Price History
            </Typography>
         </div>

         <LineGraph 
            sellerPrices={sellerPrices} 
            colors={colors}
            width={divWidth}
            height={300}/>
         <Legend
            sellerLogos={sellerLogos} 
            colors={colors}/>
      </div>
   )
}

type GraphProps = {
   sellerPrices: any,
   colors: any,
   width: any,
   height: any,
}

function LineGraph(props: GraphProps) {
   let svg: any;
   let xScale: any;
   let yScale: any;
   let line: any;

   useEffect(() => {
      if(props.sellerPrices.length !== 0) {
         drawChart();
      }
   }, [props.width]);

   function overallMinMax() {
      let overallMinDate = undefined;
      let overallMaxDate = undefined;
      let overallMinPrice = undefined;
      let overallMaxPrice = undefined;
      for(let i = 0; i < props.sellerPrices.length; i++) { // for seller
         let xMinValue = (d3.min(props.sellerPrices[i], (d: {date: string, price: Number}) => new Date(d.date))) as Date;
         let xMaxValue = (d3.max(props.sellerPrices[i], (d: {date: string, price: Number}) => new Date(d.date))) as Date;
         let yMinValue = Number(d3.min(props.sellerPrices[i], (d: any) => d.price))
         let yMaxValue = Number(d3.max(props.sellerPrices[i], (d: any) => d.price))
   
         if(i === 0) { // first seller
            overallMinDate = xMinValue;
            overallMaxDate = xMaxValue;
            overallMinPrice = yMinValue;
            overallMaxPrice = yMaxValue;
         }
         else { // check for new mins/maxes
            if(overallMinDate !== undefined && overallMinDate > xMinValue) {
               overallMinDate = xMinValue;
            }
            if(overallMaxDate !== undefined && overallMaxDate < xMaxValue) {
               overallMaxDate = xMaxValue;
            }
            if(overallMinPrice !== undefined && overallMinPrice > yMinValue) {
               overallMinPrice = yMinValue;
            }
            if(overallMaxPrice !== undefined && overallMaxPrice < yMaxValue) {
               overallMaxPrice = yMaxValue;
            }
         }
         // console.log("seller", i, ":", xMinValue, xMaxValue, yMinValue, yMaxValue)
      }
      return {overallMinDate, overallMaxDate, overallMinPrice, overallMaxPrice};
   }

   function makeGridArea() {
      let minmaxArray = overallMinMax();
      const xMinValue = minmaxArray.overallMinDate as Date;
      const xMaxValue = minmaxArray.overallMaxDate as Date;

      const yMinValue = minmaxArray.overallMinPrice as number;
      // const yMinValue = 0;
      const yMaxValue = minmaxArray.overallMaxPrice as number;
      const yDiff = yMaxValue - yMinValue
      const yMaxDigits = 
         (yMaxValue > 1)
         ? Math.ceil(yMaxValue).toString().length
         : yMaxValue.toString().length

      let margin = {
         top: 10,
         right: 50,
         bottom: 75,
         left: 60 + (yMaxDigits * 5)
      }

      d3.select('#container').selectAll("svg").remove(); //TODO add me back in
      svg = d3
         .select('#container')
         .append('svg')
         .attr('width', props.width + margin.left + margin.right)
         .attr('height', props.height + margin.top + margin.bottom)
         .append('g')
         .attr('transform', `translate(${margin.left},${margin.top})`);

      // set scales
      xScale = d3
         .scaleTime()
         .domain([xMinValue, xMaxValue])
         .range([0, props.width]);
      yScale = d3
         .scaleLinear()
         .range([props.height, 0])
         .domain([yMinValue, yMaxValue]).nice();

      // label axises
      svg.append("text")             
         .attr("transform",
               "translate(" + (props.width/2) + " ," + 
                              (props.height + margin.top + 50) + ")")
         .style("text-anchor", "middle")
         .text("Date");
      svg.append("text")
         .attr("transform", "rotate(-90)")
         .attr("y", 0 - margin.left + 10)
         .attr("x", 0 - (props.height / 2))
         .attr("dy", "1em")
         .style("text-anchor", "middle")
         .text("Price (USD)"); 

      // x-axis stuff
      svg
         .append('g')
         .attr('class', 'x-axis')
         .attr('transform', `translate(0,${props.height})`)
         .call(d3.axisBottom(xScale).tickSize(5))
         .call(d3.axisBottom(xScale)
            .ticks(d3.timeWeek.every(2))
            .tickFormat(d3.timeFormat('%b %d') as ((domainValue: AxisDomain, index: number) => string))
         )
      d3.selectAll(".x-axis .tick text")
         .attr("transform", "translate(-20, 15)rotate(-45)")

      // y-axis stuff
      if(yDiff <= 1) { // use ints only
         svg
            .append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(yScale)
               .ticks(5)
               .tickFormat(d => 
                  (Number.isInteger(d as number))
                  ? "$" + d3.format(".3r")(d as number)
                  : "$" + d3.format(".2r")(d as number)));
      }
      else if(yDiff <= 5) { // use ints only
         let yAxisTicks = yScale.ticks().filter((tick: any) => Number.isInteger(tick));
         svg
            .append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(yScale)
               .tickValues(yAxisTicks)
               .tickFormat(d => "$" + d));
      }
      else { // skip a few ticks
         svg
            .append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(yScale)
               .ticks(5)
               .tickFormat(d => "$" + d));
      }

      // line?
      line = d3
         .line()
         .x((d: any) => xScale(d.date))
         .y((d: any) => yScale(d.price))
   }

   function drawChart() {
      console.log("drawChart()")
      makeGridArea();

      for(let i = 0; i < props.sellerPrices.length; i++) {
         svg
            .append('path')
            .datum(props.sellerPrices[i])
            .attr('id', function(d: any, i: any){ 
               return "line-" + i
            })
            .attr('fill', 'none')
            .attr('stroke', props.colors[i]) //TODO colors
            .attr('stroke-width', 4)
            .attr('class', 'dataline') 
            .attr('d', line);
      }
   }

   return (
      <div id="container"></div>
   )
}

type LegendProps = {
   sellerLogos: (string | undefined)[][],
   colors: any,
}

function Legend(props: LegendProps) {

   const [toggled, setToggled] = useState(false);

   function highlight0 () {
      console.log("buttonclicked")
      setToggled(!toggled)
      d3.selectAll("path").style("opacity", +(toggled))
   }

   return(
      <div>
         <Grid container alignItems="flex-start">
            {props.sellerLogos.map(
               (seller: (string | undefined)[], index: number) => (
                  <Grid item xs={6} sm={6} md={4} justify="center" className="legend-instance">
                     <svg width="20" height="20">
                        <rect width="20" height="20" 
                           style={{fill: props.colors[index], stroke:"rgb(0,0,0)"}} />
                     </svg>
                     <div className="legend-image-container">
                        <img height={40} className="table-cell-image legend-image" src={seller[0]} alt={seller[1]}/>
                     </div>
                     
                  </Grid>
               )
            )}
         </Grid>
      </div>

   )
}