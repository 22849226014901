import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
  Hidden,
   Typography
} from '@material-ui/core';

import OrderCancellationButton from '../account/orderhistory/OrderCancellationButton';

type Props = {
  id: string,
  created: string,
  status: string,
  shippingInfo: string,
  rejectionReason: string,
  cancellable: boolean,
  setOrderChanged?: any,
};

export default function DisplayInfo(props: Props) {
  const orderDate = new Date(props.created);
  const orderDateParsed = 
    new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(orderDate)

  let firstShipping = ""
  let lastShipping = ""
  if(props.status !== "Pending") { // parse shipping info for delivery dates
    let shippingInfo = JSON.parse(props.shippingInfo)
    const sellerValues: {"taxes": string, "shipping-cost": string, "estimated-delivery": string}[] = Object.values(shippingInfo);
    let deliveryDates = [];
    for(let i = 0; i < sellerValues.length; i++) {
      let tempDelivery = Date.parse(sellerValues[i]["estimated-delivery"])
      if(!isNaN(tempDelivery)) {
        deliveryDates.push(tempDelivery)
      }
    }
    if(deliveryDates.length !== 0) {
      deliveryDates.sort(function(a, b){
        return a - b
      })
      firstShipping = 
        new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }).format(deliveryDates[0])
      lastShipping = 
        new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }).format(deliveryDates[deliveryDates.length - 1])
    }
  }

   return (
    <Grid item container className="order-grid-item">
      <Grid item container xs={12} justify="space-between">
        <Grid item>
          <Typography variant="h6" display="inline" noWrap gutterBottom={true}>
            Order Information
          </Typography>
        </Grid>
        {
        (props.cancellable === true)
        ?
          <Grid item>
            <OrderCancellationButton 
              orderId={props.id}
              setOrderChanged={props.setOrderChanged}/>
          </Grid>
        :
          ""
      }
      </Grid>
      <Grid item xs> 
        <Typography variant="body1">
            Order Number: {props.id}<br/>
            Ordered On: {orderDateParsed}<br/>
            Status: {props.status}<br/>
        </Typography>
        {
          (props.status === "Rejected")
          ?
            <Typography variant="body1">
              Rejection Reason: {props.rejectionReason}<br/>
            </Typography>
          :
            (props.status === "Completed")
            ?
              <Typography variant="body1">
                Est. Delivery Window: {firstShipping} - {lastShipping}<br/>
              </Typography>
            :
              ""
        }
      </Grid>
    </Grid>
   );
}
