import React, {createContext, useState} from 'react';
import SearchListenerCollection from '../pages/search/SearchListenerCollection';
import {Credentials, Account} from "../model/interface/DBModels";

export const AppContext = createContext({
   token: "",
   account: {},
   setCredentials: (credObj: Credentials, cb: () => void = () => {}) => {},
   searchListener: new SearchListenerCollection(''),
   searchText: "",
   setSearchText: (searchText: string) => {},
   signOut: () => {},
   toggleCartUpdate: () => {},
   secure: false,
   setSecure: (secure: boolean) => {}
});

export const AppProvider: React.FunctionComponent = (props) => {
   const [token, setToken] = useState("");
   const [account, setAccount] = useState<Account>({id: "", email: "", firstName: "", lastName: "", guest: true});
   const [searchListener] = useState(new SearchListenerCollection(""));
   const [searchText, setSearchText] = useState("");
   const [secure, setSecure] = useState(false);

   const setCredentials = (credObj: Credentials) => {
      console.log("about to set creds: " + credObj.token);
      setToken(credObj.token);
      setAccount(credObj.account);
   }

   const removeToken = () => {
      setToken("");
   }

   const toggleCartUpdate = () => {}

   return (
      <AppContext.Provider value={{
         token: token,
         account: account,
         setCredentials: setCredentials,
         searchListener: searchListener,
         searchText: searchText,
         setSearchText: setSearchText,
         signOut: removeToken,
         toggleCartUpdate: toggleCartUpdate,
         secure: secure,
         setSecure: setSecure,
      }}>
         {props.children}
      </AppContext.Provider>
   )
}