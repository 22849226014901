import React, {useContext} from "react";
import {useHistory, NavLink} from "react-router-dom";
import { useSnackbar } from 'notistack';

import {
   Grid,
   Hidden,
   Link,
   Paper, PaperProps, Typography,
} from "@material-ui/core";

import {AppContext} from '../../../contexts/AppContext';
import {Credentials, DBAbstractItem} from "../../../model/interface/DBModels";
import CartDAO from "../../../model/dao/CartDAO";
import SaveItemDialogButton from "../../projects/SaveItemDialogButton";
import AccountPromptButton from "./AccountPromptButton";

type Props = PaperProps & {
   item: DBAbstractItem,
}

export default function AbstractItemDisplay(props: Props) {
   const {
      item,
      ...rest
   } = props;

   const context = useContext(AppContext);
   let history = useHistory();
   const {enqueueSnackbar} = useSnackbar();

   const addAction = async (token: string = "", itemId:string) => {
      console.log("--> handleAddAction adding to cart: " + itemId);
      await CartDAO.addItems(token || context.token, [{quantity: 1, itemId}]);
   }

   const handleAddAction = (lowestPriceItemId:string) => {
      return (creds?: Credentials) => {
         addAction(creds?.token, lowestPriceItemId).then(() => {
            enqueueSnackbar("Item added to cart.", {variant: "success"});
            context.toggleCartUpdate();
         }).catch(() => (
            enqueueSnackbar("Could not add item.", {variant: "error"})
         ));
      }
   }

   const redirectToItem = () => {
      history.push("/item/" + item.id);
   }

   return (
      // <NavLink className="remove-link" exact to={"/item/" + item.id}>
         <Paper
            className="u-padding-1 u-marginBottom-0.5"
            variant="outlined"
            {...rest}
         >
            <Grid container spacing={2} style={{ cursor: 'pointer' }}>
               <Grid item md={2} xs={3} onClick={() => redirectToItem()}>
                  <img src={item.image} height="100" width="100" alt={item.name}/>
               </Grid>
               <Grid item md={5} xs={4} onClick={() => redirectToItem()}>
                  <Grid spacing={2} container>
                     <Grid item xs={12}>
                        <div className="paper-title">
                           {item.name}
                        </div>
                     </Grid>
                     <Hidden smDown>
                        <Grid item xs={12}>
                           <Typography variant="body2">
                              {/* {item.name} */}
                           </Typography>
                        </Grid>
                     </Hidden>
                  </Grid>
               </Grid>
               <Grid item sm={3} xs={4} onClick={() => redirectToItem()}>
                  <Grid spacing={1} container>
                     <Grid item xs={12}>
                        <Typography variant="body2">
                           {"Lowest Price:"}
                        </Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <div className="bright">
                           <Typography variant="h6">
                              {"$" + item.lowestPrice.toFixed(2)  + " / " + item.unit}
                           </Typography>
                        </div>
                     </Grid>
                     <Grid item xs={12}>
                        <Link component={NavLink} exact to={"/item/" + item.id}>
                           {item.matchCount ? "See " + item.matchCount + " prices..."
                           : "See all prices..."}
                        </Link>
                     </Grid>
                  </Grid>
               </Grid>
               <Hidden xsDown>
                  <Grid item xs={2}>
                     <div className="u-flexItem u-flexColumn u-justifyCenter u-heightFull">
                        <div className="u-paddingBottom-1 u-widthFull">
                           <AccountPromptButton
                              size="small" color="primary" variant="contained" 
                              buttonLabel="Add to Cart"
                              actionHandler={handleAddAction(item.lowestPricedItem.id)}
                           />
                        </div>
                        <div className="u-widthFull">
                           <SaveItemDialogButton
                              size="small" color="primary" variant="contained" 
                              items={[item.lowestPricedItem]}
                           />
                        </div>
                     </div>
                  </Grid>
               </Hidden>
            </Grid>
         </Paper>
      // </NavLink>
   );
}