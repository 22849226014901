import React, {useContext, MouseEvent} from "react";
import { useSnackbar } from 'notistack';
import {
   Grid,
   Hidden,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableRow,
   Typography,
} from "@material-ui/core";

import {AppContext} from '../../../contexts/AppContext';
import { Credentials, DBItem } from "../../../model/interface/DBModels";
import { Rating } from "@material-ui/lab";
import CartDAO from "../../../model/dao/CartDAO";
import AccountPromptButton from "./AccountPromptButton";
import SaveItemDialogButton from "../../projects/SaveItemDialogButton";

type Props = {
   items:DBItem[]
}

export default function PriceComparison(props: Props) {
   const {items} = props;

   const context = useContext(AppContext);

   const {enqueueSnackbar} = useSnackbar();

   const handleBuyAction = (siteURL: string) => {
      return (event: MouseEvent) => {
         event.stopPropagation();
         if (siteURL) {
            window.open(siteURL, "_blank", "noopener,noreferrer");
         }
      };
   }

   const addAction = async (itemId:string, token: string = "") => {
      console.log("--> handleAddAction adding to cart: " + itemId);
      console.log("for user: " + token + " c: " + context.token);
      await CartDAO.addItems(token || context.token, [{quantity: 1, itemId}]);
   }

   const handleAddAction = (itemId:string) => {
      return (creds?: Credentials) => {
         addAction(itemId, creds?.token).then(() => {
               enqueueSnackbar("Item added to cart.", {variant: "success"})
               context.toggleCartUpdate();
            }).catch(() => (
               enqueueSnackbar("Could not add item.", {variant: "error"})
            ));
      }
   }

   return (
      <div className="table-wrapper fill-size">
         <div className="table-display">
            <TableContainer>
               <Table className="table-size" size="small" aria-label="search result table">
                  <TableBody>
                     {items.map(
                        (item: DBItem) => (
                           <TableRow key={item.id}>
                              <Hidden xsDown> {/* large version */}
                                 <TableCell style={{ width: "35%" }} component="th" scope="row">
                                    <div className="table-cell-image-content" onClick={handleBuyAction(item.link)}>
                                       <img height={50} className="table-cell-image" src={item.sellerLogo} alt={item.seller}/>
                                    </div>
                                 </TableCell>
                                 <TableCell style={{ width: "25%" }} align="left">
                                    {<Rating name="read-only" value={4} readOnly />}
                                 </TableCell>
                                 <TableCell style={{ width: "15%" }} align="center">
                                    <Typography color="secondary" variant="body1">
                                       {"$" + item.price.toFixed(2) + " / " + item.unit}
                                    </Typography>
                                 </TableCell>
                                 <TableCell style={{ width: "35%" }} align="right">
                                    <div className="u-flexItem">
                                       <div className="u-paddingRight-1">
                                          <AccountPromptButton 
                                             size="small" color="primary" variant="contained" 
                                             buttonLabel="Add to Cart"
                                             actionHandler={handleAddAction(item.id)}
                                          />
                                       </div>
                                       <div>
                                          <SaveItemDialogButton
                                             size="small" color="primary" variant="contained" 
                                             items={[item]}
                                          />
                                       </div>
                                    </div>
                                 </TableCell>
                              </Hidden>
                              <Hidden smUp> {/* small version */}
                                 <TableCell style={{ width: "25%" }} component="th" scope="row">
                                    <div className="table-cell-image-content" onClick={handleBuyAction(item.link)}>
                                       <img height={35} className="table-cell-image" src={item.sellerLogo} alt={item.seller}/>
                                    </div>
                                    {<Rating name="read-only" value={4} readOnly />}
                                 </TableCell>
                                 <TableCell align="center">
                                    <Grid container xs={12} direction="column" spacing={1}>
                                       <Grid item>
                                          <Typography color="secondary" variant="body1">
                                             {"$" + item.price.toFixed(2) + " / " + item.unit}
                                          </Typography>
                                       </Grid>
                                       <Grid item>
                                          <AccountPromptButton 
                                             size="small" color="primary" variant="contained" 
                                             buttonLabel="Add to Cart"
                                             actionHandler={handleAddAction(item.id)}
                                          />
                                       </Grid>
                                       <Grid item>
                                          <SaveItemDialogButton
                                             size="small" color="default" variant="contained" 
                                             items={[item]}
                                          />
                                       </Grid>
                                    </Grid>
                                    {/* <Typography color="secondary" variant="body1">
                                       {"$" + item.price.toFixed(2) + " / " + item.unit}
                                    </Typography>
                                    <AccountPromptButton 
                                       size="small" color="primary" variant="contained" 
                                       buttonLabel="Add to Cart"
                                       actionHandler={handleAddAction(item.id)}
                                    />
                                    <SaveItemDialogButton
                                       size="small" color="default" variant="contained" 
                                       items={[item]}
                                    /> */}
                                 </TableCell>
                              </Hidden>

                              
                              
                           </TableRow>
                        )
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
         </div>
      </div>
   )
}
