import DAO from "./DAO";
import Endpoint from "../request/Endpoint";
import {
   DBCategories,
} from "../interface/DBModels";

class CategoryDAO extends DAO {
   private readonly categoriesEndpoint: Endpoint<{}, DBCategories>;
   private readonly tradesEndpoint: Endpoint<{}, DBCategories>;
   private readonly subcategoriesEndpoint: Endpoint<{}, DBCategories>;

   constructor() {
      super();

      this.categoriesEndpoint = Endpoint.for<{}, DBCategories>('topLevelCat');
      this.tradesEndpoint = Endpoint.for<{}, DBCategories>('topLevelTrades');
      this.subcategoriesEndpoint = Endpoint.for<{}, DBCategories>('subcategories');
   }

   async getTopLevelCategories(token: string = ""): Promise<DBCategories> {
      console.log("getting top level categories");

      const response = await this.categoriesEndpoint.get(
         {token},
      );

      this.validateResponse(response);
         
      return response;
   }

   async getTopLevelTrades(token: string = "", id: number): Promise<DBCategories> {
      console.log("getting categories for Trades");

      const urlParams = {
         token,
      }
      const queryParams = {
         trade: id
      }

      const response = await this.tradesEndpoint.get(
         urlParams,
         queryParams,
      );

      this.validateResponse(response);
         
      return response;
   }

   async subCategories(token: string = "", path: string): Promise<DBCategories> {
      console.log("getting subcategories");

      const urlParams = {
         token,
         path: path,
      }

      const response = await this.subcategoriesEndpoint.get(
         urlParams,
      );

      this.validateResponse(response);
         
      return response;
   }
}

const instance = new CategoryDAO();

Object.freeze(instance);

export default instance;