import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
   useHistory,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Typography,
   Button,
   IconButton,
   CircularProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import {AppContext} from "../../contexts/AppContext";
import {DBItem, DBAssembly} from '../../model/interface/DBModels';
import AssemblyDAO from "../../model/dao/AssemblyDAO";
import CartDAO from "../../model/dao/CartDAO";
import ResultDAO from "../../model/dao/ResultDAO";
import ItemDisplay from '../search/components/ItemDisplay';
import { Pagination } from '@material-ui/lab';
import EditTitleDisplay from './EditTitleDisplay';

export default function ProjectPage() {
   const { id } = useParams<{id:string}>();
   let history = useHistory();
   const {enqueueSnackbar} = useSnackbar();

   const context = useContext(AppContext);
   const [project, setProject] = useState<DBAssembly>();
   const [title, setTitle] = useState("");
   const [items, setItems] = useState<DBItem[]>([]);
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setError] = useState(false);
   const [isEditMode, setIsEditMode] = useState(false);
   const [maxPages, setMaxPages] = useState(1);
   const [page, setPage] = useState(1);
   // for renaming projects
   // const [newAssemblyName, setNewAssemblyName] = useState<string>('');

   React.useEffect(() => {
      let isActive = true;

      setIsLoading(true);
      setError(false);
      
      console.log("getting items for " + id);

      ResultDAO.getAssemblyItems({page: [page.toString()]}, context.token, id)
         .then((items) => {
            if (isActive) {
               setItems(items.results);
               setProject(items.assembly);
               if (items.assembly) {
                  setTitle(items.assembly?.name);
               }
               setMaxPages(items.maxPages);
               setIsLoading(false);
               setError(false);
            }
         }).catch(() => {
            if (isActive) {
               setError(true);
               setIsLoading(false);
            }
         });

      return () => {
         isActive = false;
      };
   }, [id, page]);


   const handleExportAction = () => {
   
   }

   const handleAddAllAction = () => {
      console.log("adding all to cart");
      CartDAO.addItems(context.token, items.map(item => ({
         quantity: 1,
         itemId: item.id,
      })))
         .then(() => {
         enqueueSnackbar("All items added to cart.", {variant: "success"});
         console.log("handleAddAllAction: items added to cart")
         })
         .catch(() => {
         console.log("handleAddAllAction: error adding items to cart");
         enqueueSnackbar("Unable to add items to cart.", {variant: "error"});
         });;
   }

   const handleDeleteAction = async () => {
      console.log("doing remove of " + project?.name);
      await AssemblyDAO.deleteAssembly(context.token, project?.id || "");
      history.push("/assemblies");
   }
   
   const handleRemoveAction = (item: DBItem) => {
      return () => {
         console.log("removing from: " + project?.name + " item: " + item.id);
         AssemblyDAO.removeItems(context.token, project?.id || "", [{id: item.id}]);
      }
   }

   const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
      console.log("getting page: " + page);
      setPage(page);
      window.scrollTo(0, 0);
   };

   const handleEditTitle = () => {
      setIsEditMode(true);
   }

   const handleCancelEdit = () => {
      setIsEditMode(false);
   }

   const handleSaveTitle = (newTitle: string) => {
      setTitle(newTitle);
      setIsLoading(true);

      if (project) {
         AssemblyDAO.renameAssembly({name: newTitle}, context.token, project.id)
            .then(() => {
               setIsEditMode(false);
               setIsLoading(false);
            });
      } else {
         console.log("project id not found");
         setIsEditMode(false);
         setIsLoading(false);
      }
   }

   const TitleDisplay = () => {
      if (isLoading) {
         return <CircularProgress size={"1em"}/>;
      } else if (isEditMode) {
         return (
            <EditTitleDisplay title={title} handleSaveTitle={handleSaveTitle} handleCancelEdit={handleCancelEdit}/>
         )
      } else {
         return (
            <div className="u-flexItem">
               <div className="u-marginVertical-1 u-marginRight-1">
                  <Typography variant="h5" color="primary" noWrap>
                     {title}
                  </Typography>
               </div>
               <IconButton color="primary" aria-label="edit project name" onClick={handleEditTitle}>
                  <EditIcon/>
               </IconButton>
            </div>
         );
      }
   }

   return (
      <div className="page-offset">
         <Grid container>
            <Grid item xs={12}>
               <TitleDisplay/>
            </Grid>
            <Grid item xs={12}>
               <div className="u-flexItem">
                  <div className="highlight u-paddingTop-2 u-paddingRight-2">
                        <Typography variant="body1">
                           {"Total: $" + project?.totalPrice?.toFixed(2)}
                        </Typography>
                  </div>
                  <div className="u-padding-2">
                     <Typography variant="body1" color="secondary">
                        {project?.numItems + " Items"}
                     </Typography>
                  </div>
                  <div className="u-paddingTop-1 u-paddingRight-2">
                     <Button variant="outlined" color="primary" onClick={handleAddAllAction}>
                        Add All Items to Cart
                     </Button>
                  </div>
                  <div className="u-paddingTop-1 u-paddingRight-2">
                     <Button variant="outlined" color="primary" onClick={handleExportAction}>
                        Export CSV
                     </Button>
                  </div>
                  <div className="u-paddingTop-1">
                     <Button variant="outlined" color="primary" onClick={handleDeleteAction}>
                        Delete Project
                     </Button>
                  </div>
               </div>
            </Grid>
            <Grid item xs={12}>
               {items && items.map(
                  (item: DBItem) => (
                     <ItemDisplay key={item.id} item={item} handleRemoveAction={handleRemoveAction(item)}/>
                  )
               )}
            </Grid>
            <Grid item xs={12} zeroMinWidth>
            <div className="pagination">
               <Pagination
                  shape="rounded"
                  count={maxPages}
                  page={page}
                  onChange={handlePageChange}
               />
            </div>
            </Grid>
         </Grid>
      </div>
   );
}