import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
  Paper,
   Typography
} from '@material-ui/core';

import AccountPageWrapper from '../../components/account/AccountPageWrapper';

export default function AccountSettingsPage() {
 
  return (
    <AccountPageWrapper
      title={"Account Settings"}
      selected={1}
      inside={<InsidePage />}/>
  );
}

function InsidePage() {
  return (
    <Grid item xs> {/* main page */}
      <Paper elevation={3} className="order-history-paper">
        <div className="loading-screen">
          <Typography variant="subtitle1" align="center">
            under construction! come back later :)
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
}

