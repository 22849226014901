type Param
= string[]
| string
| number
| null
| {dayOfWeek: string}[];

export type QueryParams = {
   [param: string]: Param
};
export type Params = { [param: string]: string };

/*
   Joins together the query parameters and their values into one string
   to be attached to the end of a URL
   Example
      input:
      queryParams = {
         perPage: 20,
         searchText: "Aearo",
      }
      output:
         "?perPage=20&searchText=Aearo"
   */
export function getQueryParamString(queryParams: QueryParams): string {
   const paramStrings = [];

   for (let [paramName, paramValues] of Object.entries(queryParams)) {
      if (!Array.isArray(paramValues)) {
         if (paramValues &&
            ((typeof paramValues === "number" && !isNaN(paramValues)) ||
            (typeof paramValues === "string" && paramValues !== ""))) {
            paramValues = [paramValues.toString()]; // convert numbers and strings to param array
         } else {
            paramValues = [];
         }
      }

      for (let paramValue of paramValues) {
         if (typeof paramValue === "object") { // convert day to param
            paramValue = paramValue.dayOfWeek;
         }

         paramStrings.push(`${paramName}=${paramValue}`);
      }
   }

   const fullParamString = encodeURI(paramStrings.join("&"));

   return (fullParamString.length > 0) ? `?${fullParamString}` : "";
}