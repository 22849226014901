import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';

import AccountPageWrapper from '../../components/account/AccountPageWrapper';
import PaymentEditor from '../../components/account/payments/PaymentEditor';

export default function PaymentPage() {
  const stripePromise = loadStripe('pk_test_51HaWKaJuo8U7byNnzJnvPTZN2WRmtTDhB5IvxagbluaRQ2VVqkTrzYdCb78ek9zGQDgPt1cVIhFJ83VWPO55MSRL00rcyRvVo0');

  return (
    <Elements stripe={stripePromise}>
      <AccountPageWrapper
      title={"Saved Addresses"}
      selected={3}
      inside={<PaymentEditor />}/>
    </Elements>
  );
}