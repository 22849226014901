import React, {useState, ChangeEvent} from 'react';

import {
   TextField,
   Grid,
   Button,
   Paper,
   Typography,
   AccordionSummary,
   Accordion,
   AccordionDetails,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DBFilter } from '../../model/interface/DBModels';
import DropDown from './components/DropDown';

type Props = {
   filters: DBFilter[],
   selected: { [filterName: string]: string[] },
   handleMinPrice: (val:string) => void,
   handleMaxPrice: (val:string) => void,
   handleSelection: (filtername:string) => void,
   handleFiltering: () => void,
   handleClear: () => void,
};

export default function FilterDisplay(props:Props) {
   const [minPrice, setMinPrice] = useState<string>("0");
   const [maxPrice, setMaxPrice] = useState<string>("0");
   const [toggle, setToggle] = useState(true); 
   
   const handleMinPrice = (event: ChangeEvent<HTMLInputElement>) => {
      setMinPrice(event.target.value);
      props.handleMinPrice(event.target.value);
   }
   
   const handleMaxPrice = (event: ChangeEvent<HTMLInputElement>) => {
      setMaxPrice(event.target.value);
      props.handleMaxPrice(event.target.value);
   }
   
   const handleClear = () => {
      setMinPrice("0");
      setMaxPrice("0");
      // hack to trigger rerenders of dropdowns on clear
      setToggle(!toggle);
      props.handleClear();
   }

   return (
      <Paper>
         <Accordion defaultExpanded={window.innerWidth > 960}>
            <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="filters-content"
            >
               <Typography variant="body1" color="inherit">
                  Filters
               </Typography>
            </AccordionSummary>
            <AccordionDetails>
               <div className="filter-side-column">
                  <div className="u-marginTop-0">Price</div>
                  <Grid container spacing={1}>
                     <Grid item md={6}>
                        <TextField
                           className="filter-input"
                           label="Min"
                           variant="outlined"
                           margin="dense"
                           onChange={handleMinPrice}
                           value={minPrice}
                        />
                     </Grid>
                     <Grid item md={6}>
                        <TextField
                           className="filter-input"
                           label="Max"
                           variant="outlined"
                           margin="dense"
                           onChange={handleMaxPrice}
                           value={maxPrice}
                        />
                     </Grid>
                  </Grid>
                  {props.filters.map( (filter) => {
                     return (
                        <div key={filter.key}>
                           <div>{filter.key}</div>
                           <DropDown
                              key={filter.key + toggle.toString()}
                              multiple={true}
                              inputArray={filter.values}
                              selected={props.handleSelection(filter.key)}
                              defaultValue={props.selected[filter.key]}
                           />
                        </div>
                     );
                  })}
                  <div className="u-flexItem">
                     <div className="u-paddingRight-1">
                           <Button variant="contained" color="primary" onClick={props.handleFiltering}>
                              Apply
                           </Button>
                        </div>
                        <div>
                           <Button variant="outlined" color="secondary" onClick={handleClear}>
                              Clear
                           </Button>
                        </div>
                  </div>
               </div>
            </AccordionDetails>
         </Accordion>
      </Paper>
    );
}