import React, { Component } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';



type Props = {
    handlePassChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    passwordError: boolean,
};
type State = {
    showPass: boolean,

};

class PasswordField extends Component<Props, State> {
    static defaultProps = {

    };

    state: State = {
        showPass: false,
    };

    render() {
        return (
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel htmlFor="outlined-adornment-password"> Password</InputLabel>
                {this.props.passwordError ? 
                    <OutlinedInput error
                        id="outlined-adornment-password"
                        type={this.state.showPass ? 'text' : 'password'}
                        onChange={this.props.handlePassChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {this.state.showPass ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    /> :
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={this.state.showPass ? 'text' : 'password'}
                        onChange={this.props.handlePassChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {this.state.showPass ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                }
            </FormControl>
        );
    }

    handleClickShowPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({showPass: !this.state.showPass})
    };
}

export default PasswordField;