import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, TableContainer, Table, TableBody,
} from '@material-ui/core';
import DisplayItemRow from './DisplayItemRow';
import {DBOrderedItem, DBCartItem, DBItem } from '../../model/interface/DBModels';
import SaveItemDialogButton from '../../pages/projects/SaveItemDialogButton';


type Props = { // i would like to apologize in advance for this
  items: DBOrderedItem[] | DBCartItem[] | undefined,
  status: string,
};

export default function DisplayItems(props: Props) {
  let qtyItemList: {quantity: number, item: DBItem, fulfilled: boolean}[] = [];
  let itemList: DBItem[] = []

  if(props.items === undefined ||  props.items.length === 0) {
    return(<Grid item/>);
  }

  function isOrderedItems(items: DBOrderedItem[] | DBCartItem[] | undefined): items is DBOrderedItem[] {
    if(items === undefined || items.length === 0) {
      return false;
    }
    return (items as DBOrderedItem[])[0].originalItemId !== undefined;
  }
  function isCartItems(items: DBOrderedItem[] | DBCartItem[] | undefined): items is DBCartItem[] {
    if(items === undefined || items.length === 0) {
      return false;
    }
    return (items as DBCartItem[])[0].item !== undefined;
  }
  
  if(isOrderedItems(props.items)) {
    // convert DBOrderedItem => DBItem
    for(let i = 0; i < props.items.length; i++) {
      // make an item
      let price = props.items[i].price
      if(props.items[i].updatedPrice !== null) {
        price = props.items[i].updatedPrice
      }
      let newItem: DBItem = {
        id: props.items[i].originalItemId,
        name: props.items[i].name,
        price: Number(price),
        sellerLogo: props.items[i].sellerLogo,
        seller: props.items[i].seller,
        link: props.items[i].link,
        unit: props.items[i].unit,
        exactItemGroupingId: "", //TODO add fix here
      }
      // add to array
      qtyItemList = [...qtyItemList, {quantity: props.items[i].quantity, item: newItem, fulfilled: props.items[i].fulfilled}];
      itemList = [...itemList, newItem];
    }
  }
  else if(isCartItems(props.items)) {
    // convert DBCartItem => DBItem
    for(let i = 0; i < props.items.length; i++) {
      // make an item
      let newItem = props.items[i].item;
      // add to array
      qtyItemList = [...qtyItemList, {quantity: props.items[i].quantity, item: newItem, fulfilled: true}];
      itemList = [...itemList, newItem];
    }
  }
  else { // both are undefined. stop pls
    return(<Grid item/>);
  }

  return (
    <Grid item xs={12}>
      <Grid item container justify="space-between">
        <Grid item>
          <Typography variant="h6" display="inline" noWrap gutterBottom={true}>
            Items in Order
          </Typography>
        </Grid>
        <Grid item>
          <SaveItemDialogButton
            size="small" variant="outlined" 
            items={itemList}
          />
        </Grid>
      </Grid>
      <Grid item container>
        <TableContainer className="order-item-table">
           <Table>
            {/* <TableHead className="order-item-header">
              <TableCell><u>Item Name</u></TableCell>
              <TableCell><u>Quantity</u></TableCell>
              <TableCell align="right"><u>Price</u></TableCell>
            </TableHead> */}
            <TableBody>
              {
                qtyItemList.map((orderItem: any) => {
                  return (
                    <DisplayItemRow
                      item={orderItem.item} 
                      quantity={orderItem.quantity}
                      price={orderItem.item.price}
                      fulfilled={orderItem.fulfilled} 
                      status={props.status}/>
                  )}
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
