import React, {useState, useCallback, useEffect} from "react";
import XLSX from "xlsx"

import { Button, Tab, Tabs, Typography, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import BOMUpload from './components/BOMUpload';
import SearchTab from './components/SearchTab';

const excludedNames = ["item description", "item_description"];

const csvConverter = (csvString: string) => {
   return csvString.split(/\s*(?:[\r\n]+|,|$)\s*/).filter(e => !excludedNames.includes(e.trim().toLowerCase()));
}

function a11yProps(index: any) {
   return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
   };
}

const useStyles = makeStyles((theme: Theme) => ({
   root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      height: "100%",
   },
   tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: 180,
   },
}));

export default function BOMSearchPage() {
   const classes = useStyles();
   const [value, setValue] = useState(0);
   const [searchTerms, setSearchTerms] = useState<string[]>([]);
   const [filename, setFilename] = useState("");
   const [isUploaded, setIsUploaded] = useState(false);

   useEffect(() => {
      let isActive = true;

      let recentTerms = localStorage.getItem("AS_most_recent_upload");
      let recentFilename = localStorage.getItem("AS_most_recent_upload_filename");

      if (recentTerms && recentFilename) {
         setSearchTerms(csvConverter(recentTerms));
         setIsUploaded(true);
         setFilename(recentFilename);
      }

      return () => {
         isActive = false;
      };
   }, []);

   const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
         const reader = new FileReader()
         const excelRegex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/
         const excelMIMEs = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
         
         reader.onabort = () => console.log('file reading was aborted')
         reader.onerror = () => console.log('file reading has failed')
         reader.onload = () => {
            let csvString: string;
            if (excelMIMEs.includes(file.type) || excelRegex.test(file.name)) {
               const workbook = XLSX.read(reader.result, {type: "binary"});
               const firstSheet = workbook.SheetNames[0];
               csvString = XLSX.utils.sheet_to_csv(workbook.Sheets[firstSheet]);
               console.log("csv: " + csvString);
            } else {
               console.log(reader.result)
               csvString = (reader.result as string);
            }
            // localStorage.setItem(file.name, reader.result as string);
            localStorage.setItem("AS_most_recent_upload", csvString);
            localStorage.setItem("AS_most_recent_upload_filename", file.name);
            let terms: string[] = csvConverter(csvString);
            setSearchTerms(terms);
            (terms).forEach(e => console.log("val: '" + e + "'"));
            setIsUploaded(true)
         }
         setFilename(file.name)
         if (excelMIMEs.includes(file.type) || excelRegex.test(file.name)) {
            reader.readAsBinaryString(file)
         } else {
            reader.readAsText(file)
         }
      })
      
   }, [])

   const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
   };

   return (
      <div className="page-offset">
         {!isUploaded ? <BOMUpload dropCb={onDrop} /> :
         <Grid container spacing={1}>
            <Grid item xs={8}>
               <Typography variant="h4" color="primary">
                  {filename}
               </Typography>
            </Grid>
            <Grid item xs={4}>
               <div className="u-flexItem u-flexAlignJustifyContentEnd">
                  <Button variant="contained" color="primary" className="" onClick={() => setIsUploaded(false)}>
                     New Upload
                  </Button>
               </div>
            </Grid>
            <Grid item xs={12}>
               <div className={classes.root}>
                  <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs"
                  className={classes.tabs}
                  >
                     {searchTerms.map((term, i) => (
                        <Tab key={term + i} label={term} {...a11yProps(i)} />
                        ))}
                  </Tabs>
                  {searchTerms.map((term, i) => (
                     <SearchTab key={term + i} value={value} index={i} inputText={term}/>
                  ))}
               </div>
            </Grid>
         </Grid>}
      </div>
   );
}