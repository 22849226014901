import React from 'react';

import Grid from '@material-ui/core/Grid';
import {
  Hidden,
   Typography
} from '@material-ui/core';
import { DBOrder } from '../../model/interface/DBModels';

type Props = {
  order: DBOrder | undefined,
};

export default function DisplayPrice(props: Props) {
  if(props.order === undefined) {
    return(<Grid item/>);
  }

  let currency = "$"

  function makePriceString(str: number) {
    return currency + str.toFixed(2).toString()
  }

  let subtotal = makePriceString(props.order.totalPrice)
  let shipping = "Pending"
  let taxes = "Pending"
  let service = "Pending"
  let total = "Pending"

  if(props.order?.status !== "Pending") {

    let shippingInfo = JSON.parse(props.order.shippingInfo)
    const sellerValues: {"taxes": string, "shipping-cost": string, "estimated-delivery": string}[] = Object.values(shippingInfo);

    subtotal = makePriceString(props.order.totalUpdatedPrice);

    let shippingSum = 0;
    let taxesSum = 0;
    let runningTotal = props.order.totalUpdatedPrice;
    
    for(let i = 0; i < sellerValues.length; i++) {
      let tempShipping = sellerValues[i]["shipping-cost"]
      if(tempShipping !== "") {
        shippingSum += parseFloat(sellerValues[i]["shipping-cost"])
      }
      let tempTaxes = sellerValues[i]["taxes"]
      if(tempTaxes !== "") {
        taxesSum += parseFloat(sellerValues[i]["taxes"])
      }
    }
    if(shippingSum.toString() !== "NaN") {
      shipping = makePriceString(shippingSum);
      runningTotal += shippingSum
    }
    if(taxesSum.toString() !== "NaN") {
      taxes = makePriceString(taxesSum)
      runningTotal += taxesSum
    }

    let asServiceFee = runningTotal * 0.021
    let stripeFee = ((runningTotal + asServiceFee) * 0.029) + 0.30
    service = makePriceString(asServiceFee + stripeFee)

    runningTotal += (asServiceFee + stripeFee)

    total = makePriceString(runningTotal)
  }

   return (
    <Grid item container xs={8} sm={6} md={4} justify="flex-end" direction="column">
        <Grid item container spacing={2} justify="flex-end">
          <Grid item xs>
            <Typography variant="body1" align="left" >
              Subtotal:
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" align="right">
              {subtotal}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item xs>
            <Typography variant="body1" align="left">
              Shipping:
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" align="right">
              {shipping}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item xs>
            <Typography variant="body1" align="left">
              Taxes & Fees:
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" align="right">
              {taxes}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item xs>
            <Typography variant="body1" align="left">
              Service Fee:
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" align="right">
              {service}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item xs>
            <Typography variant="body1" align="left">
              <b>Total:</b>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" align="right">
              {total}
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  );
}
