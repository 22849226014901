import React, { useState, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Paper, CircularProgress, Button, Dialog
} from '@material-ui/core';

import {
  useStripe, useElements, CardNumberElement
} from '@stripe/react-stripe-js';

import {AppContext} from '../../../contexts/AppContext';
import StripeDAO from "../../../model/dao/StripeDAO";
import { DBCard } from '../../../model/interface/DBModels';

import AddressDeletionButton from './PaymentDeletionButton';
import SavedPayments from '../../checkout/payment-page/SavedPayments';
import PaymentInput from '../../checkout/payment-page/PaymentInput';
import { labelToCode } from '../../_helpers/CountryType';

export default function PaymentEditor() {
  const context = useContext(AppContext);

  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [checkingCard, setCheckingCard] = useState(false);
  const [error, setError] = useState(false);
  const [paymentList, setPaymentList] = useState<DBCard[]>([]);
  const [paymentListChanged, setPaymentListChanged] = useState(false);

  const [stripeErrorMessages, setStripeErrorMessages] = useState<string[]>([]);
  const [editingPayment, setEditingPayment] = useState<string>();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [cardFormVisible, setCardFormVisible] = useState(true);

  React.useEffect(() => {
    let isActive = true;
    setLoading(true);
    setError(false);

    StripeDAO.getAllCards(context.token)
      .then((paymentList) => {
          if (paymentList) {
            let cards = (paymentList as any).cards;
            setPaymentList(cards);
            if(cards.length === 0) {
              setCardFormVisible(true);
            }
            if(selectedIndex !== -1) {
              setSelectedIndex(-1);
            }
            setLoading(false);
            setError(false);
          }
      }).catch(() => {
          if (isActive) {
             setError(true);
             setLoading(false);
          }
      });

    return () => {
      isActive = false;
    };
  }, [paymentListChanged]);

  React.useEffect(() => {
    setEditingPayment(paymentList[selectedIndex]?.id)
  }, [selectedIndex])

  const toggleFormVisibility = (changeTo: boolean) => {
    if(changeTo !== cardFormVisible) {
      if(changeTo === true) { // input new address
        setSelectedIndex(-1);
      }
      setCardFormVisible(changeTo);
    }
  }

  const reloadPaymentList = () => {
    setPaymentListChanged(!paymentListChanged);
  }

  const makeBillingDetailsFromForm = (formData: FormData) => {
    let countryCode = labelToCode(String(formData.get("country")));
    let newAddress = {
      "line1": String(formData.get("address-line-1")),
      "line2": String(formData.get("address-line-2")),
      "city": String(formData.get("city")),
      "state": String(formData.get("region")),
      "postal_code": String(formData.get("zip-code")),
      "country": countryCode
    }
    let newName = String(formData.get("name"));
    let newPhone = String(formData.get("phone"));
    let newDetails = {
      "address": newAddress,
      "name": newName,
      "phone": newPhone
    }
    return newDetails;
  }

  const generateFriendlyError = (errorType: string, errorMessage: string) => {
    switch(errorType) {
      case "validation_error":
        return ["Your card could not be processed.", errorMessage];
      default:
        return ["Something went wrong.", "Please check your card details and try again."]
    }
  }

  const formHandleSubmit = async (event: any) => {
    event.preventDefault();
    setCheckingCard(true);

    const clientInfo = await StripeDAO.addCard(context.token);

    /* between this comment and the next one
    is stuff to prevent typescript errors */
    if (stripe === null || elements === null) {
      throw new Error('!! stripe is null')
    }
    const cardElement = elements.getElement(CardNumberElement)
    if (cardElement === null) {
      throw new Error('!! element is null')
    }
    /* end typescript error preventors */

    let billingDetails;
    let form = document.querySelector("form");
    if(form !== null) {
      let formData = new FormData(form);
      billingDetails = makeBillingDetailsFromForm(formData);
    }
    else {
      throw new Error('!! form is null')
    }

    // thank you https://stripe.com/docs/payments/save-and-reuse#web-create-setup-intent

    const result = await stripe.confirmCardSetup(clientInfo.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: billingDetails
      }
    });

    if (result.error) {
      if(result.error.message !== undefined) {
        setStripeErrorMessages(generateFriendlyError(result.error.type, result.error.message))
      }
    } else {
      setStripeErrorMessages([]);
      reloadPaymentList();
    }
    setCheckingCard(false);
  }

  const emptyFunction = () => {

  }

   return (
    <Grid item xs> {/* outer wrapper */}
      <Dialog
        open={checkingCard}
        container={() => document.getElementById('payment-form-paper')}>
        <div className="loading-screen">
          <Typography variant="subtitle1" noWrap align="center">
            <CircularProgress size={'1em'}/>
            <br/>
            Checking your card...
          </Typography>
          </div>
      </Dialog>
      <Paper elevation={3} className="order-history-paper"> {/* items in cart */}
        {
          loading
          ?
          <div className="loading-screen">
            <Typography variant="subtitle1" noWrap align="center">
              <CircularProgress size={'1em'}/>
              <br/>
              Loading payment methods...
            </Typography>
          </div>
          :
            <form id="payment-form" onSubmit={formHandleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SavedPayments
                  paymentList={paymentList}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  cardFormVisible={cardFormVisible}
                  toggleFormVisibility={toggleFormVisibility}
                />
                {
                  (cardFormVisible === true)
                  ?
                    <PaymentInput
                        errorMessages={stripeErrorMessages}
                        sameBillingAddress={false}
                        setSameBillingAddress={emptyFunction}
                        disabledCheckbox={true}/>
                  :
                    ""
                }
              </Grid>
              <Grid container xs={12} spacing={2} justify="flex-end">
                <Grid item>
                  {
                    (selectedIndex !== -1)
                    ?
                      <AddressDeletionButton 
                        paymentId={editingPayment}
                        reloadFunction={reloadPaymentList}/>
                    :
                      ""
                  }
                </Grid>
                <Grid item>
                  <Button
                  variant="contained" color="primary" type="submit" form="payment-form"
                  disabled={loading} disableElevation>
                    save card
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            </form>
        }
      </Paper>


    </Grid>
   );
}

