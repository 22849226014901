import EndpointConfig from "./config/EndpointsConfig";

import EndpointConfigData from "./config/EndpointsConfig.json";
import fetch from 'node-fetch';

import {QueryParams, Params, getQueryParamString} from "../../components/_helpers/UrlHelper";

type RequestProps = { [prop: string]: any };
type Props = {
   basePath: string,
   requiredParams: string[],
   allowedQueryParams: string[],
   requiredQueryParams: string[],
   allowedRequestTypes: string[],
   doMask?: boolean
};

class Endpoint<Data, Response> {
   private static readonly url  = 'https://beta-api.assembledsupply.com';
   // private static readonly url  = 'http://127.0.0.1:8000';
   private static readonly config: EndpointConfig = EndpointConfigData;

   private readonly basePath: string;
   private readonly requiredParams: string[];
   private readonly allowedQueryParams: string[];
   private readonly requiredQueryParams: string[];
   private readonly allowedRequestTypes: string[];
   private readonly doMask: boolean;

   constructor(props: Props) {
      const {
         basePath,
         requiredParams,
         allowedQueryParams,
         requiredQueryParams,
         allowedRequestTypes,
         doMask = false
      } = props;

      this.basePath = basePath;
      this.requiredParams = requiredParams;
      this.allowedQueryParams = allowedQueryParams;
      this.requiredQueryParams = requiredQueryParams;
      this.allowedRequestTypes = allowedRequestTypes;
      this.doMask = doMask;
   }

   /*
      Wraps getQueryParamString to check for required query params
   */
   private getQueryParamStringWrapper(queryParams: QueryParams): string {
      for (const paramName of this.requiredQueryParams) {
         if (queryParams[paramName] === undefined) {
            throw new Error(`Endpoint: missing query parameter: ${paramName}`);
         }
      }
      return getQueryParamString(queryParams);
   }

   private getPathWithParams(params: Params): string {
      let path = this.basePath;

      for (const paramName of this.requiredParams) {
         if (params[paramName] === undefined) {
            throw new Error(`Endpoint: missing parameter: ${paramName}`);
         }
      }

      for (const [paramName, paramValue] of Object.entries(params)) {
         path = path.replace(`:${paramName}`, paramValue);
      }

      return path;
   }

   getPath(params: Params = {}, queryParams: QueryParams = {}): string {
      return Endpoint.url
         + this.getPathWithParams(params)
         + this.getQueryParamStringWrapper(queryParams);
   }

   private request<T>(
      type: string,
      props: RequestProps,
      params: Params = {},
      queryParams: QueryParams = {},
      needsStatus: boolean = false,
   ): Promise<T> {
      if(params.token) {
         props.headers.Authorization = `Token ${params.token}`;
      }

      const responseFunc = needsStatus ? ((res: { status: number }) => res)
      : ((res: { json: () => any; }) => res.json());

      if(this.allowedRequestTypes.includes(type)) {
         const path = this.getPath(params, queryParams);
         console.log("path: " + path + " props: " + JSON.stringify(props));
         return fetch(path, props)
               .then(responseFunc)
               .catch((err: any) => err);
      } else {
         throw new Error(`Cannot ${type}: ${this.basePath}`);
      }
   }

   get(params: Params = {}, queryParams: QueryParams = {}): Promise<Response> {
      const props = { headers: {} };

      return this.request<Response>('GET', props, params, queryParams);
   }

   post(
      data: Data,
      params: Params = {},
      queryParams: QueryParams = {},
      needsStatus: boolean = false,
   ): Promise<Response> {
      const props = {
         method: 'POST',
         body: JSON.stringify(data),
         headers: { 'Content-Type': 'application/json' }
      };

      return this.request<Response>('POST', props, params, queryParams, needsStatus);
   }

   put(
      data: Data,
      params: Params = {},
      queryParams: QueryParams = {}
   ): Promise<Response> {
      const props = {
         method: 'PUT',
         body: JSON.stringify(data),
         headers: { 'Content-Type': 'application/json' }
      };

      return this.request<Response>('PUT', props, params, queryParams);
   }

   delete(
      params: Params = {},
      queryParams: QueryParams = {}
   ): Promise<Response> {
      const props = { 
         method: 'DELETE',
         headers: {},
      };

      return this.request<Response>('DELETE', props, params, queryParams);
   }

   static for<Data, Response>(endpointName: string): Endpoint<Data, Response> {
      const config = Endpoint.config.endpoints[endpointName];

      if(config !== undefined) {
         return new Endpoint<Data, Response>(config);
      }  else {
         throw new Error(`Invalid endpoint: ${endpointName}`);
      }
   }
}

export default Endpoint;
