import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Grid, Typography, Card, Button, TextField, Hidden } from '@material-ui/core';

import toolboxIcon from '../../assets/AS_icon_toolbox.svg';
import personIcon from '../../assets/AS_icon_person.svg';
import lightbulbIcon from '../../assets/AS_icon_lightbulb.svg';

import CategoryButton from '../../navigation/components/CategoryButton';
import {AppContext} from '../../contexts/AppContext';
import {getQueryParamString} from "../../components/_helpers/UrlHelper";

type Event = {
   target: {
      value: string
   }
};

type NavCard = {
   name:string,
   icon:any,
   iconName: string,
   pluralName:string,
   pathName: string,
}

export default function LandingPage() {
   const cardInfo:NavCard[] = [
      {
        name: "Category",
        icon: toolboxIcon,
        iconName: "toolbox icon",
        pluralName: "Categories",
        pathName: "/categories",
      },
      {
         name: "Trade",
         icon: personIcon,
         iconName: "person icon",
         pluralName: "Trades",
         pathName: "/trades",
      },
      {
         name: "Project",
         icon: lightbulbIcon,
         iconName: "lightbulb icon",
         pluralName: "Projects",
         pathName: "/categories",
      },
    ];

    const [searchText, setSearchText] = useState('');

    let history = useHistory();

   const context = useContext(AppContext);

    const handleChange = (evt: Event) => {
      const searchText = evt.target.value;
      
      setSearchText(searchText);
   };

   const doSearch = () => {
      console.log("setting search text L: " + searchText);
      context.setSearchText(searchText);
      context.searchListener.clearQueryParams();
      context.searchListener.updateText(searchText);
      history.push({
         pathname: "/search",
         search: getQueryParamString(context.searchListener.getQueryParams()),
      });
   };

   const goToCategories = () => {
      history.push({
         pathname: "/categories",
      });
   }

   const goToBOM = () => {
      history.push({
         pathname: "/bom",
      });
   }

   const handleClick = (pathName:string) => {
      return (() => history.push(pathName));
   }

   const checkForSubmit = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
         doSearch();
      }
   };

   return (
      <div className="landing-page page-offset">
         <Box
            className="" // {/* "lg-p-top" + classes.wrapper */}
            display="flex"
            justifyContent="center"
         >
            <div className="" /* {classes.contentWrapper} */>
               <Grid container spacing={6}>
                  <Grid container item xs={12} className="background">
                     <Grid item xs={12}>
                        <div className="spacer-small"></div>
                           <Typography variant="h3" className="landing-text">
                              Find parts faster and cheaper
                           </Typography>
                        <div className="spacer"></div>
                     </Grid>
                     
                     <Grid item xs={12} className="search-area">
                        <Hidden xsDown> {/* large version */}
                           <div className="landing-btn">
                              <CategoryButton childClassName="landing-category-btn" />
                           </div>
                        </Hidden>
                        <div className="search-bar">
                           <TextField
                              id="search-bar"
                              variant="outlined"
                              onKeyDown={checkForSubmit}
                              onChange={handleChange}
                              placeholder="What are you looking for today?"
                              className="input-root input-input"
                              inputProps={{ 'className': 'search-bar-input', 'aria-label': 'search' }}
                              InputProps={{
                                 className: "search-bar-input",
                              }}
                              />
                        </div>
                        <div className="landing-btn">
                           <Button variant="contained" color="primary" className="search-btn" onClick={doSearch}>
                              Search
                           </Button>
                        </div>
                     </Grid>
                     <Hidden xsDown> {/* large version */}
                     </Hidden>
                     
                     <Grid item xs={12}>
                        <Hidden smUp> {/* small version */}
                           <Typography variant="body1" paragraph className="bom-text">
                              Want to view everything? <button className="underlined-clickable bom-text" onClick={goToCategories}>
                                 View all categories
                              </button>
                           </Typography>
                        </Hidden>
                        <Typography variant="body1" paragraph className="bom-text">
                           Already have a list? <button className="underlined-clickable bom-text" onClick={goToBOM}>
                              Search by BOM
                           </button>
                        </Typography>
                        <div className="spacer-large"></div>
                     </Grid>
                  </Grid>
                  {cardInfo.map((info, index) => (
                     <Grid key={index} item md={4} xs={12}>
                        <Card className="info-card" elevation={3}>
                           <Box pt={5} pr={5} pl={5} pb={5}>
                              <Typography variant="h6">
                                 Shop By
                              </Typography>
                              <Typography variant="h4" className="info-card-name">
                                 <b>{info.name}</b>
                              </Typography>
                              <div className="info-card-img">
                                 <img height="160" width="160" src={info.icon} alt={info.iconName}/>
                              </div>
                              <Button size="large" color="primary" variant="contained" onClick={handleClick(info.pathName)}>
                                 Browse All {info.pluralName}
                              </Button>
                           </Box>
                        </Card>
                     </Grid>
                  ))}
                  <Grid item xs={12}/>
               </Grid>
            </div>
         </Box>
      </div>
   );
}
