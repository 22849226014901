import React, {useContext} from "react"

import { Box } from "@material-ui/core"

import {AppContext} from '../../../contexts/AppContext';
import SearchPage from "../SearchPage";

type TabPanelProps = {
   children?: React.ReactNode;
   inputText: string;
   index: any;
   value: any;
}


export default function SearchTab(props: TabPanelProps) {
   const { inputText, children, value, index, ...other } = props;
   
   const context = useContext(AppContext);

   // React.useEffect(() => {
   //    let isActive = true;

   //    console.log("setting search text B: " + inputText);
   //    context.setSearchText(inputText);
   //    context.searchListener.clearQueryParams();
   //    context.searchListener.updateText(inputText);

   //    return () => {
   //       isActive = false;
   //    };
   // }, [inputText]);

   
   const doSearch = () => {
      if (value === index) {
         console.log("setting search text ST: " + inputText);
         // context.setSearchText(inputText);
         context.searchListener.clearQueryParams();
         context.searchListener.updateQueryParam("searchText", inputText);
         context.searchListener.updateText(inputText);
      }
      return (
         <Box p={3}>
            <SearchPage alternateTitleText={'Search Results for "' + inputText + '"'}/>
         </Box>
      );
   }

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`vertical-tabpanel-${index}`}
         aria-labelledby={`vertical-tab-${index}`}
         {...other}
      >
         {value === index && doSearch()}
      </div>
   );
}