import React, { useContext, useEffect, useState } from "react";
import {
   Link as RouterLink,
   useLocation,
} from "react-router-dom";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link, { LinkProps } from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import {AppContext} from "../../contexts/AppContext";
import CategoryGrid from "./CategoryGrid";
import CategoryDAO from "../../model/dao/CategoryDAO";
import {
   DBCategory,
   DBAbstractItem,
   DBFilter,
} from "../../model/interface/DBModels";
import { CircularProgress } from "@material-ui/core";
import SearchPage from "../search/SearchPage";
import { Alert } from "@material-ui/lab";

interface LinkRouterProps extends LinkProps {
   to: string;
   replace?: boolean;
 }

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

export default function BreadcrumbWrapper() {
   let { pathname } = useLocation();

   const context = useContext(AppContext);

   const [currCats, setCurrCats] = useState<DBCategory[]>([]);
   const [currItems, setCurrItems] = useState<DBAbstractItem[]>([]);
   const [currResultCount, setCurrResultCount] = useState(0);
   const [currMaxPages, setCurrMaxPages] = useState(0);
   const [filters, setFilters] = useState<DBFilter[]>([]);
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setError] = useState(false);

   let pathnames = pathname.split("/").filter((x) => x);
   const catBaseUrl = pathnames[0];
   pathnames = pathnames.slice(1); //subcategories

   useEffect(() => {
      let isActive = true;

      setIsLoading(true);
      setError(false);

      console.log("path: " + pathnames.join("/"));
      CategoryDAO.subCategories(context.token, pathnames.join("/"))
         .then((categories) => {
            console.log("setting cats");
            console.log("res:"+ categories.categories.length);
            setCurrCats(categories.categories);
            if (categories.exactItems) {
               setCurrItems(categories.exactItems.results);
               setCurrResultCount(categories.exactItems.resultCount);
               setCurrMaxPages(categories.exactItems.maxPages);
               setFilters(categories.exactItems.filters || []);
            }
         }).catch(() => {
            if (isActive) {
               setError(true);
            }
         }).finally(() => {
            setIsLoading(false)
         });

      return () => {
         isActive = false;
      };
   }, [pathname]);

   const LoadingDisplay = () => {
      if (isLoading) {
         return <CircularProgress size={"4em"} />;
      } else {
         return <div></div>
      }
   }

   const CatResultsDisplay = () => {
      if (isLoading) {
         return <div></div>;
      } else if (currCats.length) {
         return <CategoryGrid {...{categoryList: currCats}}/>;
      } else if (currItems.length) {
         return <div></div>;
      } else {
         return <Alert severity="info">Sorry, we {"couldn't"} find any subcategories.</Alert>;
      }
   };

   const ItemResultsDisplay = () => {
      if (isLoading) {
         return <div></div>;
      } else if (currItems.length) {
         return <SearchPage {...{
            fullResults: currItems,
            filters: filters,
            resultCount: currResultCount,
            maxPages: currMaxPages,
            currentPage: 1,
            alternateTitleText: "Items in this Category",//replace with category name
            resultTypeText: "Items",
         }}/>;
      } else if (currCats.length) {
         return <div></div>;
      } else {
         return <Alert severity="info">Sorry, we {"couldn't"} find any items in this category.</Alert>;
      }
   };

   return (
      <div className="page-offset">
         <div className="u-marginTop-1">
            <Breadcrumbs aria-label="breadcrumb">
               <LinkRouter color="inherit" to="/categories">
                  All Products
               </LinkRouter>
               {pathnames.map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const to = `/${catBaseUrl}/${pathnames.slice(0, index + 1).join("/")}`;
                  
                  return (
                     last ? (
                        <Typography color="textPrimary" key={to}>
                           {pathnames[index].replace(/-/g, " ")}
                        </Typography>
                     ) : (
                        <LinkRouter color="inherit" to={to} key={to}>
                           {pathnames[index].replace(/-/g, " ")}
                        </LinkRouter>
                     )
                  );
               })}
            </Breadcrumbs>
            <div className="u-marginBottom-2"/>
            <LoadingDisplay/>
            <CatResultsDisplay/>
            <ItemResultsDisplay/>
         </div>
      </div>
   );
}