import React, {Component} from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PasswordField from './PasswordField';
import UserDAO from "../model/dao/UserDAO";
import { Alert, AlertTitle } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';

import logo from "../assets/AS-title-transparent-cropped.png";
import CartDAO from "../model/dao/CartDAO"
import {AppContext} from '../contexts/AppContext';
import { Credentials } from '../model/interface/DBModels';
import {isValidEmailAddress} from '../components/_helpers/EmailChecker';

type CustomProps = WithSnackbarProps & {
    showSignUp: () => void,
    altCb?: (creds: Credentials) => void,
    closeCb: () => void,
};
type State = {
    firstName: string,
    firstNameError: boolean,
    lastName: string,
    lastNameError: boolean,
    email: string,
    emailError: boolean,
    password: string,
    passwordError: boolean,
    showAlert: boolean,
    pending: boolean,
    allowed: boolean,
};

class SignUp extends Component<CustomProps, State> {
    state: State = {
        firstName: "",
        firstNameError: false,
        lastName: "",
        lastNameError: false,
        email: "",
        emailError: false,
        password: "",
        passwordError: false,
        showAlert: false,
        pending: false,
        allowed: false,
    };
    static contextType = AppContext;

    render() {
        return (
                <div className="ln-main">
                    <div className="ln-logo">
                        <img width="278" height="28" alt="AS logo" src={String(logo)} />
                    </div>
                    <div className="ln-form">
                        <form className="ln-textBoxes" noValidate autoComplete="off">
                            <div className="ln-textBox">
                                {this.state.firstNameError ?
                                <TextField error className="ln-textField" fullWidth={true} variant="outlined" label="First Name" aria-label="First Name" inputProps={{"aria-label": "First Name"}} onChange={this.handlefirstName}/>:
                                <TextField className="ln-textField" fullWidth={true} variant="outlined" label="First Name" aria-label="First Name" inputProps={{"aria-label": "First Name"}} onChange={this.handlefirstName}/>}
                            </div>
                            <div className="ln-textBox">
                                {this.state.lastNameError ?
                                <TextField error className="ln-textField" fullWidth={true} variant="outlined" label="Last Name" aria-label="Last Name" inputProps={{"aria-label": "Last Name"}} onChange={this.handlelastName}/>:
                                <TextField className="ln-textField" fullWidth={true} variant="outlined" label="Last Name" aria-label="Last Name" inputProps={{"aria-label": "Last Name"}} onChange={this.handlelastName}/>}
                            </div>
                            <div className="ln-textBox">
                                {this.state.emailError ?
                                <TextField error className="ln-textField" fullWidth={true} variant="outlined" label="Email" aria-label="Email Address" inputProps={{"aria-label": "Email Address"}} helperText="Please enter a valid email address." onChange={this.handleEmail}/>:
                                <TextField className="ln-textField" fullWidth={true} variant="outlined" label="Email" aria-label="Email Address" inputProps={{"aria-label": "Email Address"}} onChange={this.handleEmail}/>}
                            </div>
                            <div className="ln-textBox">
                                <PasswordField passwordError={this.state.passwordError} handlePassChange={this.handlePassChange} /> 
                            </div>
                            <div className="ln-buttons">
                                <Button className="ln-button ln-left-btn" onClick={this.handleSignUp} variant="outlined" color="primary" disableElevation>
                                    Back to login
                                </Button>
                                <Button className="ln-button ln-right-btn" onClick={this.handleRegistration} variant="contained" color="primary" disableElevation disabled={!this.state.allowed}>
                                    Sign Up
                                    {this.state.pending && <CircularProgress className="u-marginLeft-1" color="secondary" size={20}/>}
                                </Button>
                            </div>
                
                        </form>
                    </div>
                    <div className="ln-alert">
                        <Alert severity="error" style={{display: this.state.showAlert ? 'flex' : 'none' }} >
                            <AlertTitle>Error</AlertTitle>
                            There was an error with registration
                        </Alert>
                    </div>
                    
                </div>
            );
    }


    handleSignUp = async () => {
        this.props.showSignUp()
    }


    handlePassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({password: event.target.value})
        if (event.target.value && this.state.email && !this.state.emailError &&
            this.state.lastName && this.state.firstName) {
            this.setState({allowed: true});
        } else {
            this.setState({allowed: false});
        }
    };

    handlefirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({firstName: event.target.value})
        if (event.target.value && this.state.email && !this.state.emailError &&
            this.state.lastName && this.state.password) {
            this.setState({allowed: true});
        } else {
            this.setState({allowed: false});
        }
    };

    handlelastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({lastName: event.target.value})
        if (event.target.value && this.state.email && !this.state.emailError &&
            this.state.password && this.state.firstName) {
            this.setState({allowed: true});
        } else {
            this.setState({allowed: false});
        }
    };

    handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value && isValidEmailAddress(event.target.value)) {
            this.setState({email: event.target.value, emailError: false});
            if (this.state.password && this.state.lastName && this.state.firstName) {
                this.setState({allowed: true});
            }
        } else {
            this.setState({email: event.target.value, emailError: true});
            this.setState({allowed: false});
        }
    };

   handleRegistration = async () => {
      let guestId = this.context.account.id;
      let lastLoginWasGuest = this.context.account.guest;

      try {
         this.setState({
            emailError: false,
            firstNameError: false,
            lastNameError: false,
            passwordError: false,
            showAlert: false,
            pending: true,
         });

         const response = await UserDAO.signup(
            {
               firstName: this.state.firstName,
               lastName: this.state.lastName,
               email: this.state.email,
               password: this.state.password
            }
         );

         if (!this.props.altCb) {
            console.log("no altCb");
            this.context.setCredentials(response);
         } else {
            console.log("using altCb");
            this.props.altCb(response);
         }

         // copy cart
         if(guestId !== "" && lastLoginWasGuest) {
            CartDAO.copyItems(response.token, {guestId: guestId});
         }

         // display snackbar
         this.props.enqueueSnackbar("Sign up successful.", {variant: "success"});

         this.props.closeCb();
      } catch (e) {
         this.setState({
            emailError: !this.state.email,
            firstNameError: !this.state.firstName,
            lastNameError: !this.state.lastName,
            passwordError: !this.state.email,
            showAlert: true
         });
      } finally {
          this.setState({pending: false})
      }
   };
}

export default withSnackbar(SignUp);