import React from 'react';
import {
   Typography, TableRow, TableCell, TextField, Hidden
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { DBItem } from '../../model/interface/DBModels';


type Props = {
  item: DBItem,
  quantity: string,
  price: string,
  fulfilled: boolean,
  status: string,
};

const DisplayItemRow = function(props: Props) {
  const quantity = Number(props.quantity)
  const price = Number(props.price)

  const isNumber = (num: any) => {
    return !(isNaN(num));
    }
 
    const priceString = () => {
       if(isNumber(props.item.unit) && props.item.unit !== "1") {
          // calculate units
          let initialString = "$" + (props.item.price).toFixed(2) + " / pack of " + props.item.unit
          let unitCost = props.item.price / Number(props.item.unit)
          let unitString = " ($" + (unitCost).toFixed(2) + " / unit)"
          return initialString + unitString
       }
       else {
          return("$" + (props.item.price).toFixed(2) + " / " + props.item.unit)
       }
    }

  return (
    <TableRow className="order-item-row">
        <TableCell component="th" scope="row" width="70%">
          <div className="table-name-cell-container table-cell-name">
            <Typography variant="body2">
              {props.item.name}
            </Typography>
            <Hidden mdDown> {/* large version */}
              <Typography variant="body2">
                Seller: {props.item.seller} • Price: {priceString()}
              </Typography>
            </Hidden>
            <Hidden lgUp> {/* small version */}
              <Typography variant="body2">
                Seller: {props.item.seller}<br/>
                Price: {priceString()}
              </Typography>
            </Hidden>
          </div>
        </TableCell>
      <Hidden xsDown> {/* large version */}
        <TableCell>
          <TextField
              className="qty-field"
              label="Qty"
              value={quantity}
              variant="outlined"
              size="small"
              inputProps={{"aria-label": "quantity" }}
              disabled={true}
            />
        </TableCell>
        <TableCell align="right">
          {
            (props.status !== "Pending")
            ?
              props.fulfilled 
              ?
                <Typography variant="body2" align="right">
                  ${(price * quantity).toFixed(2)}
                </Typography>
              : 
                <Typography variant="body2" align="right">
                  <ErrorOutlineIcon aria-label="unavailable"/>
                </Typography>
            : 
              <Typography variant="body2" align="right">
                ${(price * quantity).toFixed(2)}
              </Typography>
          }
        </TableCell>
      </Hidden>
      <Hidden smUp>
        <TableCell width="30%">
          <TextField
              className="qty-field"
              label="Qty"
              value={quantity}
              variant="outlined"
              size="small"
              inputProps={{"aria-label": "quantity" }}
              disabled={true}
            />
          <br/>
          <br/>
          {
            (props.status !== "Pending")
            ?
              props.fulfilled 
              ?
                <Typography variant="body2" align="right">
                  ${(price * quantity).toFixed(2)}
                </Typography>
              : 
                <Typography variant="body2" align="right">
                  <ErrorOutlineIcon aria-label="unavailable"/>
                </Typography>
            : 
              <Typography variant="body2" align="right">
                ${(price * quantity).toFixed(2)}
              </Typography>
          }
        </TableCell>
      </Hidden>
    </TableRow>
   );
}

export default DisplayItemRow;
