import React, { useState, useContext } from 'react';

import {
   NavLink as RouterLink,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import {
   Typography, Divider, Button,
   TableContainer, Table, TableRow, TableBody, TableCell, Hidden, Paper,
   CircularProgress,
} from '@material-ui/core';

import {AppContext} from '../../contexts/AppContext';
import { Pagination } from '@material-ui/lab';
import { DBAssembly } from '../../model/interface/DBModels';
import ResultDAO from "../../model/dao/ResultDAO";
import AssemblyDAO from "../../model/dao/AssemblyDAO";
import CreateProjectDialogButton from "./CreateProjectDialogButton";

export default function ProjectsList() {
   const context = useContext(AppContext);
   const [projects, setProjects] = useState<DBAssembly[]>([]);
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setError] = useState(false);
   const [maxPages, setMaxPages] = useState(1);
   const [page, setPage] = useState(1);
   const [projectsChanged, setProjectsChanged] = useState(false);

   React.useEffect(() => {
      let isActive = true;

      setIsLoading(true);
      setError(false);

      ResultDAO.getAllAssemblies({page: [page.toString()]}, context.token)
         .then((projects) => {
            if (isActive) {
               setProjects(projects.results);
               setProjectsChanged(false);
               setMaxPages(projects.maxPages);
               setIsLoading(false);
               setError(false);
            }
         }).catch(() => {
            if (isActive) {
               setError(true);
               setIsLoading(false);
            }
         });

      return () => {
         isActive = false;
      };
   }, [projectsChanged, page]);

   const handleExportAction = () => {
      
   }

   const handleDeleteAction = (assembly: DBAssembly) => {
      return () => {
         console.log("doing remove of " + assembly.name);
         AssemblyDAO.deleteAssembly(context.token, assembly.id);
         toggleAssembliesChanged();
      }
   }

   const toggleAssembliesChanged = () => {
      setProjectsChanged(!projectsChanged);
   }

   const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
      console.log("getting page: " + page);
      setPage(page);
      window.scrollTo(0, 0);
   };

   const itemCountString = (num: number | undefined) => {
      if(num === 1) {
         return " Item"
      }
      else {
         return " Items"
      }
   }

   return (
      <Grid container item>
         <Grid item xs={12} zeroMinWidth>
            <div className="projects-button-bar">
               <CreateProjectDialogButton {...{newProjectAdded: () => toggleAssembliesChanged()}}/>
            </div>
         </Grid>
         <Grid item xs={9}>
            <Paper className="u-margin-2">
               {isLoading ?
                  <CircularProgress size={"2em"}/>
               :
                  (projects.length === 0)
                  ?
                  <div className="loading-screen">
                     <Typography variant="subtitle1" noWrap align="center">
                        No projects found.
                     </Typography>
                  </div>
                  :
                  <TableContainer>
                     <Table className="table-wrapper table-display table-size" size="small" aria-label="search result table">
                        <TableBody>
                           {projects.map(
                              (project: DBAssembly) => (
                                 <TableRow key={project.id}>
                                    <TableCell style={{ width: 180 }} component="th" scope="row">
                                       <div className="table-name-cell-container">
                                          <div className="table-cell-name">
                                             <RouterLink exact to={"/assemblies/" + project.id} >
                                                <Typography variant="h6">
                                                   {project.name}
                                                </Typography>
                                             </RouterLink>
                                          </div>
                                       </div>
                                    </TableCell>
                                    <TableCell style={{ width: 80 }} align="center">
                                       <div className="highlight">
                                             <Typography variant="body1">
                                                {"Total: $" + project.totalPrice?.toFixed(2)}
                                             </Typography>
                                       </div>
                                    </TableCell>
                                    <TableCell style={{ width: 100 }} align="center">
                                       <Typography variant="body1" color="secondary">
                                          {project.numItems + itemCountString(project.numItems)}
                                       </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: 40 }} align="right">
                                       <Button size="small" color="primary" onClick={handleExportAction}>
                                          Export CSV
                                       </Button>
                                    </TableCell>
                                    <TableCell style={{ width: 50 }} align="right">
                                       <Button size="small" color="primary" onClick={handleDeleteAction(project)}>
                                          Delete Project
                                       </Button>
                                    </TableCell>
                                 </TableRow>
                              )
                           )}
                        </TableBody>
                     </Table>
                  </TableContainer>
               }
            </Paper>
         </Grid>
         <Hidden mdDown>
            <Grid item xs={1}>
               <Divider orientation="vertical" />
            </Grid>
         </Hidden>
         <Hidden mdDown>
            <Grid item xs={2}>
               Ad Space Here
            </Grid>
         </Hidden>
         <Grid item xs={12} zeroMinWidth>
            <div className="pagination">
               <Pagination
                  shape="rounded"
                  count={maxPages}
                  page={page}
                  onChange={handlePageChange}
               />
            </div>
         </Grid>
      </Grid>
   );
}